@import 'src/utils/variables.scss';

.detector-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;
  margin-top: 5rem;
  padding: 2rem;

  p {
    margin-bottom: 2rem;
  }

  .aic-btn-outline {
    background: white;
    color: #32343a;
    border: 1px solid #32343a;
  }
}
