@import 'src/utils/variables';
@import 'src/utils/font-variables';
// CANVAS  SVG
.viewer-canvas {
  rect {
    fill: $main-background-color;
  }

  .svg-controls {
    display: flex;
    position: absolute;
    z-index: 9;
    bottom: 4rem;
    right: 4rem;

    .svg-control-btn {
      user-select: none;
      margin: 4px;
      background: $main-background-color;
      border: 1px solid gray;
      padding: 9px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      -webkit-transition: all 0.6s ease-out;
      -moz-transition: all 0.6s ease-out;
      transition: all 0.6s ease-out;

      &:hover {
        background: gray;
        color: white;
      }
    }
  }
}

// spare part info css

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px white;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-accent;
}

.spareparts-table-row {
  display: flex;
  justify-content: space-between;
}

.spareparts-table-footer {
  height: 57px;
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  background: $main-background-color;
  align-items: flex-end;
  justify-content: flex-end;

  .cart-count {
    font-size: 13px;
    color: $jet-gray;
    padding: 0 5px 8px 5px;
    display: flex;
    align-items: center;
  }

  .spareparts-table-footer-btns {
    display: flex;
    padding-top: 16px;

    & > div {
      height: 40px;
      margin-right: 1rem;
      padding: 10px 24px;
    }
  }
}

.plus-button {
  &:hover {
    fill: $primary-accent;

    rect {
      stroke: $primary-accent;
    }

    path {
      fill: white;
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
  background: #323431 !important;
}

// spareparts css

.hello-spareparts-message-title {
  font-size: 26px;
}

.hello-spareparts-message {
  font-size: 16px;
}

.breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 30px;
  background: transparent !important;
  margin: 0 !important;
  padding: 0 !important;

  &-title {
    @include m-26;
  }

  &-data {
    display: flex;
    @include r-16;
  }

  &-data {
    div:first-child {
      @include m-16;
    }
  }
}

.breadcrumbs-container {
  z-index: 1;
  position: absolute;

  .breadcrumb {
    z-index: 1 !important;
    position: absolute !important;
    background: #ffffff00 !important;
  }

  ol {
    background: #ff000000;
  }
}

.selected-at-tree {
  background: $primary-accent;
}

.spareparts-root {
  background: $main-background-color;
  transition: all 0.5s ease-in;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 4rem 0 0 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-loading {
    justify-content: center;
  }
}

.sparepart {
  cursor: pointer;

  text {
    &:hover ~ .circle {
      fill: $primary-accent !important;
    }

    &:hover + .circle {
      fill: $primary-accent !important;
    }
  }

  &.circle {
    fill: #f2f2f3;
    transition: all 0.5s ease-in;

    &:hover {
      fill: $orange !important;
    }
  }
}
.sparepart:hover > .circle {
  fill: $orange !important;
}
.sparepart:hover > text {
  fill: white !important;
}

line.selected-at-tree-arrow ~ .circle {
  fill: $orange !important;
}
line.selected-at-tree-arrow ~ text {
  fill: white !important;
}

g.selected-at-tree-arrow + circle {
  fill: $orange !important;
}

g.selected-at-tree-arrow ~ text {
  fill: white !important;
}



.selected-svg-part {
  stroke: $orange;
  fill: $orange !important;
}
.selected-svg-part + text {
  fill: white !important;
}

.selected-at-tree-arrow {
  stroke: $orange;
}

.padded-tree-item {
  padding-left: 0.7rem;
}

.sparepart-filename {
  padding-left: 10px;
  border-radius: 5px;
}

.spareparts-button {
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background: $primary-accent;
  }
}

.file-tree-container {
  display: flex;
  height: -webkit-fill-available;
  transition: all 0.5s ease-in;
}

.file-tree {
  padding: 0.5rem;
  width: 22rem;
  background: #32343a;
  border-radius: 5px;
  color: white;
  overflow-y: auto;
}

.svg-and-table-container-left {
  transition: all 0.5s ease-in;
  display: flex;
  width: 100%;
  height: 100%;
}

.svg-and-table-container-right {
  transition: all 0.5s ease-in;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  padding: 2rem;
}

.svg-container {
  width: 100%;
}

.svg-not-available {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    width: 15rem;
    height: 15rem;
  }
}

.sparepart-kit-element {
  margin: 2px 0 1px 12px;
  border-top: 1px solid #80808085;

  .sub-sparepart-index {
    font-weight: 600;
  }
}

.spareparts-table-container {
  height: 100%;
  overflow-y: auto;
  border-bottom: 1px #7a7d85 solid;

  .table {
    @include r-13;

    thead {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
      background: #fff;
      color: #7a7d85;

      th {
        font-weight: 400;
      }
    }
  }
}

.selected-part-info {
  position: absolute;
  background: beige;
}

.selected-part {
  fill: $primary-accent;
}

g path {
  cursor: pointer;

  &:hover {
    background: $primary-accent;
  }
}

.active-sparepart-on-table {
  background: #e5e5e6 !important;
  display: block;
}

.sparepart-index {
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .breadcrumb {
    &-title {
      font-size: 24px;
      line-height: 14px;
    }

    &-data {
      font-size: 14px;
    }

    &-data {
      div:nth-child(2n) {
        padding-right: 16px !important;
      }
    }
  }
}
