.product-history-timeline-card-details {
  height: 100%;
  overflow: hidden;
  transition: 1s ease;
  padding: 24px 24px 0 24px;

  .checkbox-element {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    align-items: center;

    &-threestate {
      display: flex;
      border: 1px #242424 solid;
      border-radius: 7px;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      min-width: 35px;
      padding: 0 4px;
    }

    svg {
      margin-right: 8px;
    }
  }

  .history-action {
    position: relative;
    padding-bottom: 24px;

    &--button-container {
      display: flex;
      justify-content: flex-end;
    }

    &--ribbon {
      color: #fff;
      width: 100%;
      word-wrap: break-word;
      position: absolute;
      inset: 10px calc(-1*10px) auto auto;
      padding: 0 10px 10px 25px;
      clip-path:
          polygon(0 0,100% 0,100% calc(100% - 10px),calc(100% - 10px) 100%,
              calc(100% - 10px) calc(100% - 10px),0 calc(100% - 10px),
              15px calc(50% - 10px/2));
      background: #43A9B1;
      box-shadow: 0 calc(-1*10px) 0 inset #0005;
    }

    &--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
    }

    &-name {
      font-family: Geomanist-Bold;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0;
      text-align: left;
      padding-bottom: 8px;
      color: #32343a;
    }

    &-value {
      font-family: Geomanist-Regular;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      text-align: left;
      color: #32343a;
    }

    svg {
      margin-right: 8px;
    }

    &-edit-image {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &-add-image {
      display: block;
      margin-right: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      right: -18px;
      bottom: 24px;

      &--close {
        width: 1.75rem;
        height: 1.75rem;
        position: absolute;
        background: white;
        z-index: 1;
        right: 0;
        cursor: pointer;
      }
    }

    &-upload-image {
      margin-right: 0 !important;
      padding: 2px 14px;

      svg {
        margin-right: 0 !important;
        margin-left: 10px;
      }
    }

    &-image {
      width: 100%;
      aspect-ratio: 1 / 1;

      &--container {
        width: 30%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 15px;
        align-items: center;
      }
    }
  }
}
