$primary-accent: #6e6d6d;
$primary-accent-light: #ff9566;
$platinum-gray: #d0d3d4;
$davys-gray: #4b4f54;
$eerie-black: #101820;
$new-black: #32343a;
$jet-gray: #7a7d85;
$light-jet-gray: #e5e5e6;
$another-gray: #b0b1b5;
$light-hover-gray: #74767d;
$jet-active: #32343a;

$eerieBlack: #303033;
$romanSilver: #878a8e;
$antiFlashWhite: #dadada;
$white: #ffffff;
$orange: #ff4f00;
$light-orange: #fa7b37;
$dotColor: #737a82;
$addressColor: #45464c;

$main-background-color: #f2f2f3;

$dark-background-color: #32343a;
//eerie-black to dark-background-color

$dark2-background-color: #484a51;
//new-black to dark2-background-color

@mixin bolded {
  font-family: Geomanist-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

@mixin with-shadow {
  box-shadow: 8px 8px 15px rgba(50, 52, 58, 0.05);
}

.bolded {
  @include bolded;
}

.hide-scrollbar {
  overflow: hidden !important;
  outline: none;
}

.underline {
  text-decoration: underline;
}

.text-elipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.centered-text {
  text-align: center;
}

.centered-div {
  display: flex;
  justify-content: center;
}

.aic-link {
  text-decoration: underline;
  cursor: pointer;
  color: #4f5052;

  &:hover {
    color: #7c7e80;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
  }
}

// buttons
.aic-btn-outline {
  white-space: nowrap;
  border: 1px solid #484a51;
  background: transparent;
  padding: 8px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;

  &:hover {
    background: $light-hover-gray;
    color: white;
  }
}

.aic-btn-black {
  white-space: nowrap;
  border: 1px solid #484a51;
  background: $jet-active;
  color: white;
  padding: 8px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;

  &:hover {
    background: white;
    color: $jet-active;
  }
}

.aic-btn {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: none;
  padding: .75rem 1.5rem;
  background: #32343a;
  color: white;
  margin-right: 16px;
  cursor: pointer;
  &:hover {
    background: $light-hover-gray;
    color: white;
  }
}

.aic-small-btn {
  background: #32343a;
  height: 24px;
  padding: 2px 35px 4px 10px;
  color: white;
  cursor: pointer;
  font-size: 13px;
  width: fit-content;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;

  &:hover {
    background: $light-hover-gray;
  }
}

.aic-btn-orange {
  outline: none;
  background: $orange;
  height: 40px;
  padding: 0.5rem 2rem 0.5rem 2rem;
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: 1px solid $orange;

  &:hover {
    background: $primary-accent-light;
    border: 1px solid $primary-accent-light;
  }
}

.aic-small-btn-orange {
  background: $primary-accent;
  height: 24px;
  padding: 2px 35px 4px 10px;
  color: white;
  cursor: pointer;
  font-size: 13px;
  width: fit-content;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: 1px solid $primary-accent;

  &:hover {
    background: $primary-accent-light;
    border: 1px solid $primary-accent-light;
  }
}

.aic-small-btn-outline {
  background: $main-background-color;
  height: 24px;
  padding: 2px 35px 4px 10px;
  color: #32343a;
  border: 1px solid #32343a;
  cursor: pointer;
  font-size: 13px;
  width: fit-content;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;

  &:hover {
    background: #32343a;
    color: white;
  }
}

.full-size-btn {
  height: 48px;
  width: 100%;
  text-align: center;
  line-height: 43px;
  font-size: 16px;
}

// modal

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-300px);
  transition: all 1000ms ease-in-out;
  position: fixed;
  z-index: 7;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(122, 122, 121, 0.75);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-300px);
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Content {
  padding: 0 0;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
  outline: 0 !important;
}

.ReactModal__Contsent::-webkit-scrollbar {
  width: 0 !important;
}

// common table stuff

.quantity-modificator-cell {
  display: flex;
  align-items: center;

  .quantity-modificator {
    display: flex;
    font-size: 18px;
    font-weight: 500;

    .quantity-number {
      font-weight: 600;
      width: 20px;
      text-align: center;
    }

    .quantity-button {
      padding: 0 8px 0 8px;
      cursor: pointer;

      &:disabled {
        user-select: none;
        pointer-events: none;
      }

      &:hover {
        color: $primary-accent;
      }
    }
  }
}

/* The customcheck */
.customcheck {
  display: block;
  position: relative;
  margin: 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: none;

    &:checked ~ .checkmark {
      background-color: $primary-accent;
      // border-radius: 5px;
    }

    &:checked ~ .checkmark:after {
      display: block;
      border-width: 0 2px 2px 0;
      margin: -2px -1px;
    }

    &:disabled ~ .checkmark {
      opacity: 0.5;
      background: gray;
    }
  }

  .checkmark {
    position: absolute;
    //top: 3px;
    //left: 3px;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: 1px solid #6e6d6d;
    background: transparent;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == tab-port {
    @media (min-width: 46.875em) {
      @content;
    } // 750px
  }

  @if $breakpoint == tab-land {
    @media (min-width: 63.9em) {
      @content;
    } // 1024px
  }

  @if $breakpoint == laptop {
    @media (min-width: 75em) {
      @content;
    } // 1200px
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 120em) {
      @content;
    } // 1920px
  }

  @if $breakpoint == phone-land {
    @media (max-width: 46.874em) and (max-height: 28.125em) and (orientation: landscape) {
      @content;
    } // max w: 749px h: 450px and landscape
  }
}

// typography

@mixin subheading {
  font-family: Geomanist-Regular;
  font-size: 1.1rem;

  @include respond(tab-port) {
    font-size: 2.2rem;
  }
}

@mixin title {
  font-family: Geomanist-Medium;
  font-size: 1.5rem;

  @include respond(tab-port) {
    font-size: 3rem;
  }
}

@mixin headline {
  font-family: Geomanist-Light;
  font-size: 2.8rem;

  @include respond(tab-port) {
    font-size: 4.2rem;
  }

  @include respond(tab-land) {
    font-size: 3.4rem;
  }
}

@mixin display {
  font-family: Geomanist-Regular;
  font-size: 2.1rem;

  @include respond(tab-port) {
    font-size: 4.2rem;
  }
}

@mixin footerBottom {
  font-family: Geomanist-Regular;
  font-size: 1.4rem;
}

// mixins

// orange dot

@mixin orange-dot {
  span {
    display: inline-block;
    color: $orange;
    font-family: Geomanist-Bold;
    font-size: 2.8rem;

    @include respond(tab-port) {
      font-size: 3.4rem;
    }
  }
}

@mixin dottedSqare {
  background-color: transparent;
  margin: 3rem 0 0 2.4rem;
  @include respond(tab-port) {
    margin: 5rem 0 0 5rem;
  }
}

@mixin arrowRight {
  width: 6rem;
  height: auto;
  margin: 0 1rem 1rem 0;
  @include respond(tab-port) {
    width: 12rem;
    margin: 0 3rem 2rem 0;
  }
  justify-self: end;
  align-self: end;
  background-color: transparent;
  grid-area: arrow;
}

// Animations

@mixin appear {
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin flow {
  @keyframes flow {
    from {
      opacity: 0;
      transform: translateX(-60vw);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
