/* --------------------------------

Primary style




-------------------------------- */

.attachment-thumbnail {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 0 0 12px #00000061;
}

.attachments {
  display: flex;
  flex-wrap: wrap;
}

.attachment-item {
  transition: 200ms;
  margin: 0.3rem;
  width: 200px;
  cursor: pointer;
  border-radius: 8px;
  height: 200px;
  box-shadow: 0 0 12px #00000061;
}

.attachment-item:hover {
  box-shadow: 0 0 23px black;
}

.attachment-item img,
video {
  border-radius: 8px;
}

.attachment-video {
  overflow: hidden;
  border-radius: 8px;
  background: black;
}

.attachment-item video {
  height: 200px;
  width: 200px;
}

.attachment-video svg {
  margin: 7px;
  position: absolute;
  color: #ff4e01;
  opacity: 0.5;
  transition: 300ms;
}

.attachment-video:hover svg {
  opacity: 1;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cd-timeline__date {
  color: #e4e4e4;
}

.action-details {
  color: #8e8e8e;
}

.actionParamName.title-text {
  color: #b9bfc5;
}

.nested-param {
  padding-top: 2px;
}

.nested-param p {
  line-height: 19px !important;
  padding-left: 2rem;
}

.nested-param p .title-text {
  font-weight: 400;
}

.more-details {
  margin-bottom: -14px;
  color: #7b7e8285;
  float: right;
  /*opacity: 0;*/
}

.more-details:hover {
  color: #ff5900;
  cursor: pointer;
}

.cd-timeline__content:hover .more-details {
  /*opacity: 1;*/
  transition: 400ms;
}

img {
  max-width: 100%;
}

.btn:focus {
  box-shadow: unset !important;
}

.timeline-collaper svg {
  transition: 400ms;
}

.timeline-collaper:hover svg {
  color: #ff4f00;
}

.timeline-title {
  position: absolute;
  top: 1.5rem;
  left: 15%;
  font-size: 2rem;
  color: #838382;
}

@media (min-width: 1170px) {
  .timeline-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 610px) {
  .timeline-title {
    left: 18%;
  }
}

/* --------------------------------

Patterns - reusable parts of our design

-------------------------------- */

/* --------------------------------

Vertical Timeline - by CodyHouse.co

-------------------------------- */

.hidden-action {
  opacity: 0.2;
}

.cd-timeline {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.cd-timeline__container {
  position: relative;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 6em 0;
}

.cd-timeline__container::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  box-shadow: 0px -54px 20px black;
  background: -webkit-linear-gradient(top, #d7e4ed, #ffffff00) !important;
}

.cd-timeline__block {
  position: relative;
  margin: 2em 0;
}

.cd-timeline__block:after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

.cd-timeline__block:first-child {
  margin-top: 0;
}

.cd-timeline__block:last-child {
  margin-bottom: 0;
}

.cd-timeline__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 8px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 4px #ff5900, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 4px #ff5900, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.cd-timeline__img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}

.cd-timeline__img.cd-timeline__img--picture {
  background: #75ce66;
}

.cd-timeline__img.cd-timeline__img--movie {
  background: #3b4249;
}

.cd-timeline__img.cd-timeline__img--location {
  background: #f0ca45;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline__content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 8px;
  padding: 1em;
  -webkit-box-shadow: 0 3px 0 #d7e4ed;
  box-shadow: 1px 1px 33px #0000004d;
  //background:hsla(213, 12%, 24%, 0.32);
  background: rgba(228, 228, 228, 0.07);

  color: #e4e4e4;
}

.cd-timeline__content:after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

.cd-timeline__content::before {
  /* triangle next to content block */
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #ffffff0d;
}

.cd-timeline__content p,
.cd-timeline__read-more,
.cd-timeline__date {
  /*font-size: 1.3rem;*/
}

.cd-timeline__content p {
  /* margin: 1em 0; */
  /* line-height: 1.6; */
  margin: 0;
  line-height: 26px;
}

.cd-timeline__read-more,
.cd-timeline__date {
  display: inline-block;
}

.cd-timeline__date {
  //float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}

.responsive-timeline {
  @media only screen and (min-width: 768px) {
    .cd-timeline__content h2 {
      /*font-size: 2rem;*/
    }
    .cd-timeline__content p {
      /*font-size: 1.6rem;*/
    }
    .cd-timeline__read-more,
    .cd-timeline__date {
      /*font-size: 1.4rem;*/
    }
  }

  @media only screen and (min-width: 1170px) {
    .cd-timeline__content {
      margin-left: 0;
      //padding: 1.5em;
      width: 45%;
      /* Force Hardware Acceleration */
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    .cd-timeline__content::before {
      top: 18px;
      left: 100%;
      border-color: transparent;
      border-left-color: #32343a;
    }
    .cd-timeline__read-more {
      float: left;
    }
    .cd-timeline__date {
      position: absolute;
      width: 100%;
      left: 120%;
      top: -1px;
      /*font-size: 1.6rem;*/
    }
    .cd-timeline__block:nth-child(even) .cd-timeline__content {
      float: right;
    }
    .cd-timeline__block:nth-child(even) .cd-timeline__content::before {
      top: 15px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #ffffff0d;
    }
    .cd-timeline__block:nth-child(even) .cd-timeline__read-more {
      float: right;
    }
    .cd-timeline__block:nth-child(even) .cd-timeline__date {
      left: auto;
      right: 120%;
      text-align: right;
    }
    .cd-timeline__content.cd-timeline__content--bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-2 0.6s;
      animation: cd-bounce-2 0.6s;
    }
  }

  @media only screen and (min-width: 1170px) {
    /* inverse bounce effect on even content blocks */
    .cd-timeline__block:nth-child(even)
      .cd-timeline__content.cd-timeline__content--bounce-in {
      -webkit-animation: cd-bounce-2-inverse 0.6s;
      animation: cd-bounce-2-inverse 0.6s;
    }
  }

  @media only screen and (min-width: 1170px) {
    .cd-is-hidden {
      visibility: hidden;
    }
  }

  @media only screen and (min-width: 1170px) {
    header {
      height: 300px;
      line-height: 300px;
    }
    header h1 {
      /*font-size: 2.4rem;*/
    }
  }

  @media only screen and (min-width: 1170px) {
    .cd-timeline {
      margin-top: 0;
      padding-bottom: 6em;
      margin-bottom: 0;
    }
    .cd-timeline__container::before {
      left: 50%;
      margin-left: -2px;
    }
  }

  @media only screen and (min-width: 1170px) {
    .cd-timeline__block {
      margin: 4em 0;
    }
  }

  @media only screen and (min-width: 1170px) {
    .cd-timeline__img {
      width: 30px;
      height: 30px;
      left: 50%;
      margin-left: -15px;
      /* Force Hardware Acceleration */
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    .cd-timeline__img.cd-timeline__img--bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-1 0.6s;
      animation: cd-bounce-1 0.6s;
    }
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
