.warranty-extend-container {
  position: absolute;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  padding: 1rem;

  .login-step-title {
    margin-bottom: 2rem;
    font-size: 26px;
  }

  .login-welcome-text {
    display: flex;
    gap: 1rem;
    color: black;
    font-size: 16px;
  }

  .login-error-text {
    margin: 2rem 0;
    font-size: 16px;
  }
}
