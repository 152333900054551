.product-history-timeline-block {
  .timeline {
    position: relative;
    height: 100%;
    &-icon {
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px #0000000d;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      top: 17px;
      left: 3px;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .timeline-line {
    position: absolute;
    margin: 50px 0 0 26px;
    height: calc(100% + 40px);
    width: 1px;
    background: #32343a;
    overflow: visible;
    z-index: 2;
  }
  .hidden-timeline-icon {
    background: #d6d3d3;
  }
}
