.login-card-register {
  margin: auto;
  float: none;
  color: white !important;
  width: 350px;
}

.error {
  color: #ff2f28;
}

.error-margin {
  display: block;
  margin-bottom: 22px !important;
}

.register-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: #464e57;
  color: #fff;
  z-index: 100000;
}

.chekbox-form {
  font-size: 0.85rem;

  a {
    display: contents;
  }

  .span-link {
    color: #ff4f01;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: #5d646a;
    }
  }
}

.required-dot {
  color: red;
  font-weight: 900;
}

.new-validation-error {
  width: 100%;
  height: 12px;
  margin-top: -19px;
  margin-left: 2px;
}
