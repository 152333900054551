.product-history-timeline-card {
  background: white;
  //margin: 24px 0px 40px 92px ;
  position: relative;
  height: auto;
  z-index: 2;
  transition: 1s ease;
  box-shadow: 0px 0px 10px 0px #0000000d;
  &:hover {
    box-shadow: 0px 8px 30px 0px #989393;
  }
}

.product-history-timeline-card::before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  background: white;
  top: 61.5px;
  left: -4px;
  transform: rotate(45deg);
  z-index: 1;
}

.hidden-card {
  background: #d6d3d3;
}

.hidden-card::before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  background: #d6d3d3;
  top: 61.5px;
  left: -4px;
  transform: rotate(45deg);
  z-index: 1;
}
