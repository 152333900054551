@mixin checkbox-styles {
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 2px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      border: solid 2px #cbcbcd;
      border-radius: 2px;
    }

    &:checked:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      background-color: #32343a;
      border: solid 3px white;
      border-radius: 2px;
    }

    &:disabled:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      background-color: #cbcbcd;
      border: solid 3px white;
      border-radius: 2px;
    }
  }
}
