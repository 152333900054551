@import 'src/utils/font-variables';

@mixin recent-items-styles {
  .recent-items {
    display: flex;
    font-size: 13px;
    flex-wrap: wrap;
    gap: 16px;

    .recent-item {
      background: #ffffff;
      cursor: pointer;
      padding: 6px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 calc(33% - 8px);
      flex-wrap: nowrap;
      border: 1px solid transparent;
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      @include r-13;

      @media (max-width: 1200px) {
        flex-basis: calc(50% - 8px);
      }

      @media (max-width: 800px) {
        flex-basis: 100%;
      }

      &:hover {
        color: #ffffff;
        background: #32343a;

        .vertical-separtor {
          background-color: #fff;
        }
      }
      .model-index {
        @include m-13;
      }

      .vertical-separtor {
        width: 1px;
        height: 100%;
        margin-inline: 6px;
        background-color: #000;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
      }
    }
  }
}
