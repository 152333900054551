.product-info-card {
  &-img {
    text-align: center;

    img {
      height: 250px;
    }
  }

  &-list {
    p {
      font-size: 13px;
    }

    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    padding: 2rem 1rem 1rem;
    font-size: 13px;

    &-item {
      width: 120px;
      height: minmax(60px, auto);
      display: flex;
      flex-direction: column;

      :first-child {
        font-weight: bold;
      }

      &:last-child {
        width: 100%;
        height: auto;
      }
    }
  }
}
