@import 'src/utils/variables';

.header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: $main-background-color;
  height: 30px;
  p {
    margin: 0;
  }
  &-title {
    p {
      font-weight: 600;
      //font-size: 24px;
    }
  }
  &-product-info {
    display: flex;
    height: 20px;
    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

@media (min-width: 425px) {
  .header {
    &-title {
      p {
        font-size: 14px;
      }
    }
    &-product-info {
      p {
        font-size: 10px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .header {
    &-title {
      p {
        font-size: 20px;
      }
    }
    &-product-info {
      p {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 1224px) {
  .header {
    &-title {
      p {
        font-size: 24px;
      }
    }
    &-product-info {
      p {
        font-size: 16px;
      }
    }
  }
}
