.product-info-card {
  &-documents {
    padding-bottom: 16px;
    &-item {
      display: flex;
      flex-direction: row;
      padding: 0 16px 8px;
      align-items: center;
      svg {
        margin-right: 11px;
      }
      a {
        text-decoration: underline !important;
        color: #32343a;
      }
    }
  }
}
