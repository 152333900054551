.bg-light {
  background-color: #e0e0e0 !important;
}

.bg-custom-light {
  background: #323431 !important;
  color: white;
}

.product-info-content p {
  margin-bottom: 0;
}

.product-info-content {
  height: 100% !important;
}

.product-info-product-type {
  height: 70vh !important;
}

hr {
  margin-top: 0.2rem !important;
  margin-bottom: 0.2rem !important;
}

.text-white-30 {
  color: #ffffffd6 !important;
}

.bg-darker {
  background: rgba(26, 26, 27, 0.37) !important;
  color: #353a40;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.03) !important;
  border-radius: 24px !important;
}

.bg-lighter {
  background-color: #f8f9fab5 !important;
}

.title-text {
  font-weight: 500;
}

.container-fluid {
  padding: 0 !important;
}

.product-info-orange-field {
  padding: 2rem 1rem 2rem 1rem !important;
}

.document-link {
  color: #e0e1e2;
  padding-left: 7rem;
  font-weight: 500;
}

.document-link:hover {
  color: #343a40;
}

.product-info-orange-field p {
  padding-left: 7rem;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-info-orange-field p {
    padding-left: 1rem;
    font-size: 0.9rem;
  }

  .document-link {
    padding-left: 0;
    font-size: 0.9rem;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .product-info-orange-field p {
    padding-left: 0;
  }

  .document-link {
    padding-left: 0;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .product-info-orange-field p {
    padding-left: 3rem;
    font-size: 0.9rem;
  }

  .document-link {
    padding-left: 3rem;
    font-size: 0.9rem;
  }
}

.product-documents {
  padding-top: 1rem;
}

.product-documents h4 {
  padding-top: 0.5rem;
}

.orange-product-info-pane {
  background: rgb(255, 79, 0);
  color: rgb(38, 38, 50);
  line-height: 25px;
  border-radius: 24px;
  margin-bottom: -1rem;
  box-shadow: rgba(0, 0, 0, 0.79) 2px 11px 14px;
  z-index: 93;
}

.boiler-type-name {
  font-size: 5rem;
  font-weight: 900;
  position: absolute;
  text-shadow: rgba(0, 0, 0, 0.09) 0 0 13px;
  bottom: 0;
  right: 0;
  margin-right: 2rem;
}

.product-info-image {
  height: auto;
  max-height: -webkit-fill-available;
  filter: drop-shadow(6px 2px 24px #bebebe2e);
  width: auto;
}

.product-localization {
  margin-top: 1.2rem;
  height: 100%;
  background: #e0e0e0;
  border-radius: 6px !important;
}
