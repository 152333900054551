@import 'src/utils/font-variables';

.table {
  .table-row {
    cursor: pointer;
    margin: 0 1rem 0 0;
    &-open {
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05),
        0px 8px 30px rgba(50, 52, 58, 0.07);
    }

    &:hover {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .part-details {
    border-bottom: 1px solid #e5e5e6;
    &-image {
      max-width: 150px;
      padding-left: 10px;
    }
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      background: #ffffff;
      padding: 16px 16px 16px 0;
    }
    &-info {
      //height: 124px;
      &-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
      }
      &-item {
        display: flex;
        flex-direction: row;
        padding: 0rem 3rem;
        span {
          color: #7a7d85;
          padding-left: 4px;
        }
      }
      &-kit {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 1rem 3rem;
        @include m-13;
        div {
          cursor: pointer;
        }
      }
    }
  }
}
