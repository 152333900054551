@import '../../../utils/variables';

.product-wrapper {
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-rows: 0fr 20fr;
  grid-template-areas:
    'header  header  header'
    'history history info';
  grid-gap: 40px;
  max-width: 1400px;
  width: 100%;
  margin: auto;
  min-height: 100vh;
  height: auto;
  padding: 100px 0 0;
  background: $main-background-color;
  font-family: Geomanist-Regular;
  p {
    margin: 0;
    padding: 0;
  }
}

.product-container {
  height: 100vh;
}

@media (max-width: 1400px) {
  .product-wrapper {
    padding: 100px 30px 0;
    grid-gap: 15px;
  }
}

@media (min-width: 1824px) {
  .product-wrapper {
    p {
      font-size: 14px;
    }
    .header {
      &-title {
        p {
          font-size: 32px;
        }
      }
      &-product-info {
        p {
          font-size: 18px;
        }
      }
    }
  }
  .product-info-card-header {
    //font-size: 24px;
  }
}
