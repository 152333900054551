@import 'src/utils/font-variables';

.no-results-container {
  padding: 70px 0;
  @include m-26;

  .no-results-by-SN {
    @include r-26;
  }
}
