.data-card {
  &--container {
    box-shadow: 1px 1px 20px 0 #d0cfcf;
    background: white;
    padding: 1rem 2rem;
    font-size: 13px;
    margin: 0 0 2rem 0;
    display: flex;
    align-content: center;
    align-items: center;
  }

  &--content {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
  }

  &--item {
    &--container {
      flex-flow: wrap;
      display: flex;
    }

    white-space: nowrap;
    padding-right: 1rem;
    color: #7a7d85;
    display: list-item;
    margin-left: 1.3em;
    list-style-type: disc;
    text-decoration: none;

    &:hover {
      color: #7a7d85;
    }
  }
}
