@import 'src/utils/font-variables';
@import 'src/utils/variables.scss';
@import 'src/utils/checkbox-styles';

.find-parts-search-input {
  transition: 1s ease;
  transition-delay: 0.5s;
  margin-top: 3rem;

  .spareparts-number-input {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    @include r-16;

    svg {
      margin: -3px 5px 0 !important;
      color: #7a7d85;
    }

    .input-icon-box {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  input {
    outline: none;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    @include r-16;

    &::placeholder {
      @include r-16;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  input:focus::placeholder {
    color: transparent;
    background: #ffffff;
  }

  @include checkbox-styles;
}

.distribution-styles {
  margin-top: 5rem;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.buttons-description {
  font-size: 14px;
  margin-right: 0.5rem;
}

.search-method-btn {
  height: 1.6rem;
  padding: 0.2rem 1rem;
  margin: 0 0.2rem;
  font-size: 13px;
  line-height: 17px;
  color: $dark-background-color;
  border: 1px $dark-background-color solid;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: $dark-background-color;
    color: white;
  }
}

.pressed {
  background-color: $dark-background-color;
  color: white;
  border: 1px solid $dark-background-color;
  transform: translateY(1px);
}

.search-by-sn-switcher {
  display: flex;
  align-items: center;
  padding: 0.2rem;
  font-size: 0.9rem;

  .checkbox-wrapper {
    margin: 0 0.5rem 0 0;
  }

  .label-text {
    cursor: pointer;
  }
}

.error-message {
  margin-top: 0.5rem;
  color: #7a7d85;
  font-size: 13px;
}
