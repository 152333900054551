@import 'src/utils/font-variables';

.table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  background: #f2f2f3;
  padding: 44px 0 24px 0;
  @include m-26;

  .spareparts-scroll-button {
    cursor: pointer;
    border-radius: 50%;
  }

  .spareparts-scroll-button:hover {
    background: #ffffff;
    box-shadow: 0 5px 20px 0 #32343a0d, 0 0 10px 0 #0000001a;
  }
}
