@import 'src/utils/variables.scss';

.add-action-button {
  background: #32343a !important;
  color: white;
  cursor: pointer;
  font-size: 27px;
  margin-top: 8px;
}

.add-new-action-container {
  background: white;
  color: $jet-active;
  border-radius: 0;
  padding: 0;
  box-shadow: 0px 8px 30px 0px #989393;

  &::before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    background: #32343a;
    top: 46.5px;
    left: 96px;
    transform: rotate(45deg);
    z-index: 1;
  }

  .in-circle {
    background: white;
    border: 0px solid #afb0b4;
    border-radius: 90px;
    width: 28px;
    height: 28px;
    font-size: 13px;
    padding: 3px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 7px 4px #00000012;

    svg {
      width: 16px;
      height: 16px;
      margin: 3px;
    }
  }

  .add-new-action-header {
    cursor: pointer;
    background: #32343a;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 14px 24px;
    align-content: space-around;

    .add-new-action-header-action-info {
      display: flex;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;

      div {
        margin-left: 1rem;
      }
    }
  }

  .add-action-container {
    color: $jet-active;

    .actions-list-title {
      font-size: 13px;
      margin: 1rem 1rem 0.6rem 1rem;
    }

    .actions-list-bottom-section {
      margin: 1rem;
      display: flex;
      justify-content: flex-end;

      button {
        &:disabled {
          opacity: 0.5;
          cursor: wait;
        }
      }
    }

    .actions-list {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      padding-inline-start: 0;
      margin: 0rem 1rem 1rem 1rem;

      .actions-list-item {
        cursor: pointer;
        margin: 4px 0px 4px 0px;
        background: #f2f2f3;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;

        .actions-list-item-header {
          padding-left: 16px;
        }
      }
    }
  }
}

//.actions-list {
//  padding-inline-start: 0;
//  margin: 0rem 2rem 2rem 2rem;
//
//  li {
//    font-size: 0.95rem;
//    list-style-type: none;
//    border-bottom: 0.5px solid #fff;
//    padding: 0.3rem 0 0.3rem .1rem;
//    //margin: 0 !important;
//  }
//
//  img {
//    width: 1.7rem;
//    padding-right: .3rem;
//  }
//
//  li:hover {
//    font-weight: bold;
//    cursor: pointer;
//
//    .fa-1x {
//      font-size: 1.1em;
//    }
//  }
//}

.add-action-container {
  position: relative;
}

.come-back-action {
  //color: #00ff28;
  border-radius: 50%;
  width: 1.8rem;
  text-align: center;
  border: 1px solid;
  margin-bottom: 0.5rem;
}

.come-back-action:hover {
  color: #ff4f00;
  cursor: pointer;
}

.close-actions-button {
  position: absolute;
  top: 0.4rem;
  right: 0.3rem;

  svg {
    font-size: 1.3rem;
  }
}

.close-actions-button:hover {
  color: #bf4101 !important;
  cursor: pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.react-datepicker {
  display: flex !important;
  z-index: 1000000000 !important;
}
