.product-history-wrapper {
  grid-area: history;
  display: flex;
  flex-direction: column;

  background: transparent;
  height: 100%;
  .product-history-action {
    background: pink;
    height: 10rem;
    width: 100%;
  }
  .product-history-body {
    background: transparent;
    position: relative;
    height: 50rem;
    //width: 100%;
  }
}
