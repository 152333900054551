.product-info-options {
  padding-bottom: 1rem;

  .option-line {
    padding: 2px 2rem;
    font-size: 14px;
    cursor: default;

    .option-number {
      font-weight: bold;
    }
  }
} 