@import 'src/utils/variables.scss';

.posting-action {
  cursor: wait;
}

.action-fields-container {
  text-align: left;
  font-size: 0.8rem !important;

  .location-picker-container {
    height: 15rem;
    width: 100%;
    position: relative;
  }

  .action-fields-fieldslist {
    margin: 1rem 1rem 0 1rem;

    .brand-new-input {
      border: 1px solid gray;

      .last-locations {
        display: none;
        position: absolute;
        z-index: 3;
        background: white;
        padding: 0.5rem 1rem;

        div {
          cursor: pointer;
        }

        .location-item {
          cursor: pointer;

          &:hover {
            background: #4b4f54;
            color: white;
          }
        }
      }

      .show-location {
        display: block !important;
      }

      .fade-container-open div {
        z-index: 9;
        height: fit-content !important;
      }

      .parts-list-container {
        background: white;
      }

      .brand-new-input-label {
        width: fit-content;
        margin: -8px 0px 0px 6px;
        background: #ffffff;
        padding: 0 4px 0 4px;
        font-size: 11px;
        position: absolute;
        z-index: 2;
      }

      .upload-file-text {
        text-underline: auto;
      }

      .label {
        margin: 0;
      }

      input {
        width: 100%;
        outline: none;
      }

      textarea {
        width: 100%;
        outline: none;
        margin-top: 3px;
        margin-bottom: -3px;
      }

      .react-select__control {
        border: none transparent;
        box-shadow: none;
      }

      .react-select__menu {
        z-index: 3;
      }

      .react-select__indicator-separator {
        display: none;
      }
    }
  }
}

.part-picker-container {
  .parts-list-container {
    width: 100%;
    min-height: 3rem;
    max-height: 18rem;
    border-radius: 0;
    overflow-y: auto;
    border-top: 1px solid gray;
  }

  .set-part-container {
    padding: 0.3rem;
  }

  .part-search-input {
    width: 100%;
    border-radius: 0;
    outline: none;
  }

  .part-container:last-child {
    border-bottom: none;
  }

  .highlighted {
    background-color: #bebebe;
    font-weight: 800;
  }

  .skit-container {
    cursor: pointer;

    &:hover {
      background: #f2f2f3;
    }
  }

  .skit-title {
    font-weight: 600;
  }

  .skit-description {
    font-size: 0.75rem;
  }

  .single-part-container {
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background: #f2f2f3;
    }
  }

  .part-title {
    font-weight: 700;
  }

  .part-subtitle {
    font-size: 0.75rem;
  }
}

.selected {
  background-color: #ff4f00 !important;
}

.react-select__option {
  color: #464e57 !important;
}

.checklist-container {
  border: 1px solid $jet-gray;
  padding: 0 0.5rem;

  .brand-new-input-label {
    width: fit-content;
    margin: -16px 0px 0px 6px;
    background: #ffffff;
    padding: 0 4px 0 4px;
    position: absolute;
    z-index: 2;
  }
}

.file-upload-input {
  .brand-new-input-label {
    margin: -16px 0 10px 28px;
    position: absolute;
    z-index: 2;
    background: white;
    padding: 0 4px;
  }
}

.input-container {
  font-size: 0.8rem !important;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 4px !important;

  .last-value {
    color: $jet-gray;
    font-size: 10px;
    display: flex;
  }

  .item-list-container {
    border: 1px solid gray;
    padding: 0.5rem;

    .brand-new-input-label {
      margin: -23px 0px 0px 0px;
      background: white;
      width: fit-content;
      padding: 0 4px;
    }

    .item-list-item {
      display: flex;
      align-content: flex-start;
      align-items: center;
      justify-content: space-around;

      .item-list-remove {
        padding: 1px 5px 1px 5px;
        background: white;
        border: 1px solid $jet-gray;
        margin-left: 6px;
        transition: all 0.2s ease-in;

        &:hover {
          background: $jet-gray;
          color: white;
        }
      }

      .item-list-index {
        padding: 12px;
      }

      .item-list-row {
        width: 100%;
      }
    }

    .item-list-separator {
      width: 100%;
      border-bottom: 1px solid $jet-gray;
    }

    .replace-another-part-button {
      background: white;
      width: 100%;
      border: 1px solid $jet-gray;
      margin-top: 16px;
      padding: 6px;
      transition: all 0.2s ease-in;

      &:hover {
        background: $jet-gray;
        color: white;
      }
    }
  }
}

.label {
  margin: 0.4rem 0;
}

input {
  // border-radius: 4px;
  padding: 8px;
  border: none;
}

.form-control {
  font-size: 0.8rem !important;
  height: calc(1.9em + 0.75rem + 2px) !important;
}

.comment-input {
  border: none;
  height: calc(1.9em + 0.75rem + 2px) !important;
}

.input-group-append {
  button {
    font-size: 0.8rem !important;
    z-index: 0 !important;
  }
}

.checkbox-field {
  border: 1px white solid;
  border-bottom: none;
  padding: 0.3rem;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  label {
    margin-bottom: 0;

    span {
      margin-left: 0.5rem;
      display: flex;
    }
  }
}

.checkbox-field:last-child {
  border-bottom: 1px white solid;
}

.intercept-field {
  margin-top: 1rem;
  padding: 1rem;

  .label {
    font-weight: bold;
    margin-top: 0;
  }

  .intercept-checkbox {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    label {
      margin-left: 8px;
    }
  }
}

.perform-action-button {
  width: 100%;
  max-width: 10rem;
  border: 1px solid #fff;
  border-radius: 4px;
  margin: 1rem auto 0 auto;
  text-align: center;
  padding: 0.3rem;
  cursor: pointer;
  background-color: #ff4f00;

  svg {
    margin-right: 0.3rem;
  }
}

.perform-action-button:hover {
  background-color: inherit;
}
