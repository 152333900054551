@import 'src/utils/variables.scss';

.new-login-navbar {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.controls-left {
  margin-right: 2rem;
}

.login-photo-side-container {
  width: 100%;
  height: 100%;
  margin-right: 2rem;
  max-width: 900px;
}

.login-photo-side {
  width: 100%;
  height: 100%;
  margin-right: 2rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
  .login-photo-side-container {
    display: none;
  } /* hide it elsewhere */
}

.square-logotype {
  width: 64px;
  height: 64px;
  margin-left: 5rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.login-form-side {
  display: flex;
  flex-direction: row;
  min-width: 300px;
  margin: 5rem 2rem;
  align-items: center;

  .login-welcome {
    .login-welcome-title {
      color: #32343a;
      font-size: 13px;
      padding-bottom: 1rem;
    }
    .login-welcome-text {
      color: black;
      font-size: 26px;
    }
  }
}

.login-welcome-buttons {
  display: flex;
  margin-top: 2rem;

  .login-btn {
    background: white;
    color: #32343a;
    border: 1px solid #32343a;
  }
}

.login-step {
  width: 100%;

  .login-step-title {
    margin-bottom: 2rem;
    font-size: 26px;
  }
}

.remeber-and-forgot-password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;
  margin-top: 1rem;

  .remember-password {
    font-size: 13px;

    input {
      margin-right: 3px;
    }
  }

  .reset-password-link p,
  a {
    text-decoration: none !important;
    color: black;

    &:hover {
      color: $primary-accent;
      text-decoration: none;
    }
  }
}

.already-a-member {
  margin-top: 1rem;
}

.span-link {
  font-size: 13px;
  &:hover {
    cursor: pointer;
    color: $primary-accent;
  }
}

.warn-input-icon {
  left: -24px;
  top: 12px;
  position: absolute;
}

.login-form-content {
  display: flex;
  flex-direction: column;
}

// custom input

.custom-input.custom-select {
  border: none;
  box-shadow: none;
}

.floating-label-up {
  top: -10px;
  left: 10px;
  font-weight: 300;
  font-size: 12px;
  position: absolute;
  padding: 0 4px;
  background-color: #fff;
  transition: 0.2s ease all;
}

.custom-input {
  outline: none;
  position: relative;
  border: 1px solid black;
  margin: 1rem 0 1rem 0;

  input {
    width: 100%;
    padding-left: 26px;
    background-color: #fff !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      & ~ .floating-label {
        top: -10px;
        left: 10px;
        font-size: 12px;
        padding: 0 4px;
        background-color: #fff;
      }
    }
  }

  .input-icon {
    position: absolute;
    top: 12px;
    left: 8px;
  }
}

.custom-input .floating-label {
  position: absolute;
  pointer-events: none;
  top: 8px;
  left: 26px;
  font-weight: 300;
  font-size: 15px;
  transition: 0.2s ease all;
}
.custom-input input:focus ~ .floating-label,
.custom-input input:not(:focus):valid ~ .floating-label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  opacity: 1;
  padding: 0 4px;
  background-color: #fff;
}

.custom-input-filled {
  top: -10px;
  left: 10px;
  opacity: 1;
  background-color: #fff;
}

.button-icon:hover {
  transition: 0.4s ease all;
  color: $primary-accent;
}

//override dropdown
.new-login-container-with-nav {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;

  .new-login-container {
    background: white;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
  }

  .dropdown-menu {
    left: -69px !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }

  .dropdown-item {
    background: white !important;

    &:hover {
      background: #d3d5da !important;
    }
  }

  .dropdown-toggle {
    background-color: transparent;
    border-color: #fff;
    border-style: solid;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}
