@import 'src/utils/font-variables';

.find-parts-search-input {
  transition: 1s ease;
  transition-delay: 0.5s;
  padding: 10px 0px 30px 0px;
  .spareparts-number-input {
    display: flex;
    justify-content: center;
    align-items: center;
    @include r-26;
    svg {
      margin: 3px 5px;
      //color: rgba(255, 255, 255, 0.5);
    }
  }

  input {
    outline: none;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  input:focus::placeholder {
    color: transparent;
    background: rgba(255, 255, 255, 0.5);
  }
}
