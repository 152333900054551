@import 'src/utils/recent-items-styles';
@import 'src/utils/font-variables';

.recent-products {
  padding-bottom: 40px;

  @include recent-items-styles;
}
.recent-title {
  padding-bottom: 24px;
  @include m-26;
}
