@mixin m-13 {
  font-family: Geomanist-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin r-13 {
  font-family: Geomanist-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin r-16 {
  font-family: Geomanist-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin m-16 {
  font-family: Geomanist-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin r-26 {
  font-family: Geomanist-Regular;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin m-26 {
  font-family: Geomanist-Medium;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
