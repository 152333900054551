@import '../../../../../../utils/font-variables';
@import '../../../../../../utils/variables';

.inner-body {
  .spareparts-table-footer {
    background: $main-background-color;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 24px 182px;

    .spareparts-table-footer-btns-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      border-top: solid black 1px;
      background: $main-background-color;
      position: relative;

      .spareparts-table-footer-btns {
        display: flex;
        padding-top: 16px;

        .pagination-block {
          position: absolute;
          left: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .page-counter {
            @include r-16;
            font-size: 24px;
          }

          button {
            margin: 10px;
            outline: none;
            background: transparent;
            cursor: pointer;
            border-radius: 50%;
            border: none;

            &:hover {
              background: #ffffff;
              box-shadow: 0 5px 20px 0 #32343a0d;
            }
          }
        }

        & > div {
          height: 40px;
          margin-right: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .inner-body {
    .spareparts-table-footer {
      padding: 0 100px;

      .spareparts-table-footer-btns-container {
        width: 100%;
      }
    }
  }
}
