@import 'src/utils/variables.scss';

.cookies-modal {
  width: 100%;
  max-width: 50rem;
  border-radius: 0;
  background: white;
  color: #32343a;
  font-size: 15px;
  outline: none;
  padding: 2rem !important;

  .link {
    font-weight: 600;
    padding-left: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #32343a !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

.cookies-checkboxes {
  display: inline-flex;
  padding-top: 1rem;
  flex-wrap: wrap;

  div {
    width: 12rem;
    height: 2rem;
  }
}

.cookies-buttons {
  padding-top: 2rem;
  justify-content: flex-end;
  display: flex;

  button {
    margin-left: 2rem;
  }
}

/* The customcheck */
.customcheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 2rem;
}

/* Hide the browser's default checkbox */
.customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 3px;
  height: 16px;
  width: 16px;
  background-color: #eee;
  // border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.customcheck:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customcheck input:checked ~ .checkmark {
  background-color: $primary-accent;
  // border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customcheck input:checked ~ .checkmark:after {
  display: block;
}

.customcheck input:disabled ~ .checkmark {
  opacity: 0.5;
  background: gray;
}

/* Style the checkmark/indicator */
.customcheck .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.orange {
  color: $primary-accent;
}

.link {
  font-weight: 600;
  padding-left: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #32343a !important;
  &:hover {
    text-decoration: underline;
  }
}

h4 {
  padding-top: 2rem;
}

.showDetails {
  justify-content: flex-end;
  display: flex;
}

.cookies-modal-overlay {
  align-items: start;
  padding-top: 2rem;
}
