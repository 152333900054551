@import 'src/utils/variables.scss';
@import 'src/utils/checkbox-styles';

.preferences-modal {
  width: 57rem;
  color: $platinum-gray;
  overflow-y: visible !important;

  .preferences-modal-content {
    background: transparent;
    border: none;
    min-height: 700px;
    box-shadow: 0 16px 119px #00000091;
    z-index: 999 !important;


    .profile-menu-content {
      background: #fff !important;
      padding: 1rem;
      width: 100%;
    }

    .profile-menu {
      background: $dark2-background-color;
      min-width: 15rem;
    }
  }
}

.react-modal-overlay {
  background-color: rgba(200, 200, 200, 0.9) !important;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal {
  color: #bdbfbf !important;
}

.tos-section {
  margin-top: 2.5rem;
  font-size: 0.8rem;
  color: $dark-background-color;

  a {
    display: contents;
  }
}

.span-link {
  cursor: default;
  color: $dark-background-color;
  font-size: 0.8rem;
  font-weight: normal;

  &:hover {
    text-decoration: none;
  }
}

.modal-body {
  padding: 0;
}

.modal .row > div > div {
  padding: 2rem;
}

.modal-dialog {
  margin: auto;
}

.modal-content {
  background-color: unset !important;
  display: flex !important;
  flex-direction: row !important;
}

.document-link-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  font-size: 0.8rem;
}

.preferences-padding {
  padding: 0 !important;
}

.preferences-header {
  transform: translateY(-4rem);
  position: absolute;
  top: 0;
  left: 0;
  color: $dark-background-color;
  font-weight: bold;
}

.preferences-menu-button {
  width: 100%;
  color: #b0b1b5;
  text-align: left;
  padding-left: 2rem;
  font-family: 'Geomanist-Regular';
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.preferences-menu-button:hover {
  transition: 0.5s ease;
  color: #fff;
}

.preferences-menu-item {
  padding: 1.3rem 1rem 1.3rem 2rem;
  position: relative;
  font-family: 'Geomanist-Regular';
  color: #b0b1b5;
  cursor: pointer;
  transition: 0.5s ease;
}

.preferences-menu-item.selected {
  background: $dark-background-color !important;
  color: white;
  font-family: 'Geomanist-Medium';
}

.preferences-menu-item-square {
  background: transparent;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(45deg);
  transition: 0.5s ease;
}

.selected .preferences-menu-item-square {
  background: $dark-background-color;
}

.select-menu-item:hover {
  background: rgb(255, 79, 0);
  cursor: pointer;
}

.select-menu-item {
  padding: 8px;
}

.my-custom-select::after {
  float: right;
  margin-top: 9px;
}

.preferences-section-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.preferences-section {
  width: 100%;
}

.preferences-section-header {
  font-size: 1rem;
  color: $dark-background-color;
  font-weight: bold;
}

.preferences-container {
  height: 100%;
  padding-bottom: 5.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.preferences-container-fieldset {
  display: flex;
}

.preferences-container-fieldset-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &--wrong-password {
    color: red;
    font-size: 0.8rem;
    padding-left: 10px;
  }
}

.preferences-fieldset {
  padding: 0 1rem;
  background-color: transparent;
  border: rgba(139, 139, 139, 0.6) solid 1px;
  margin: 0.5rem;
  width: 100%;

  & .dropdown-menu-right {
    background: #181a1b;
    color: #e4e4e4;
  }
}

.service-company {
  width: 100%;
}

.preferences-fieldset-width {
  width: auto;
}

.preferences-fieldset legend {
  color: $dark-background-color;
  font-size: 0.8rem;
  background-color: transparent;
  float: none;
  width: auto;
  margin: 0;
}

.my-input {
  background: white !important;
  color: #8b8b8b !important;
  border: none !important;
  padding: 0 !important;
  outline: none !important;
}

.form-control {
  box-shadow: none !important;
}

.my-custom-select {
  color: #bdbfbf;
  min-height: 38px;
  border: none !important;
  outline: none !important;
  scroll-behavior: smooth;
}

.my-custom-select:hover {
  cursor: pointer;
  color: $dark-background-color;
}

.user-menu {
  color: #8b8b8b !important;
  padding: 0.5rem 0 !important;
  font-size: 0.8rem !important;
}

.user-menu:hover {
  background-color: transparent !important;
}

.apperance-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: 2s;

  .arrow {
    margin-right: 1rem;
    margin-bottom: 3px;
    cursor: pointer;
    border: solid $dark-background-color;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    transition: 0.5s ease-in-out;
  }

  .arrow-rotate {
    transform: rotate(45deg);
  }

  .profile {
    justify-content: space-between;
  }
}

.apperance-form-show {
  opacity: 1;
  width: 100%;
}

.padding-left0 {
  padding-left: 0;
}

.cookies-settings-container {
  font-size: 0.7rem;
  margin-top: -0.5rem;
  background-color: #f2f2f3;
  padding: 0.5rem;
}

.cookies-checkboxes-settings {
  display: flex;
  padding: 0;
  margin: -0.5rem 0 0 0;
}

.cookies-customcheck {
  margin-bottom: 0.5rem !important;
}

.cookies-checkboxes-item-desc {
  display: flex;
  font-size: 0.8rem;
  padding-top: 1px;
}

.cookies-checkboxes-inf-svg {
  position: relative;
}

.preferences-toolTipText {
  visibility: hidden;
  width: 20rem;
  background-color: $dark-background-color;
  color: $platinum-gray;
  padding: 0.5rem;
  margin-top: 2rem;
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  left: 65%;
  transform: translate(-50%, -0);
  z-index: 1000;
}

.preferences-toolTipText-title {
  color: #fff;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.preferences__filtersList_container_items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.preferences__filtersList_input {
  margin: 0 0.5rem 0 0;
}

.preferences__filtersList_input_text {
  margin-bottom: 0;
}

.preferences__filtersList_label {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  font-size: 0.9rem;

  @include checkbox-styles;
}

.cookies-checkboxes-inf-svg:hover .preferences-toolTipText {
  visibility: visible;
}

.cookies-checkboxes-inf-svg svg {
  width: 1rem !important;
  height: 1rem !important;
  margin-left: 0.5rem;
  opacity: 0.8;
}

.cookies-settings-container-p {
  margin-top: 0.5rem;
}

.preferences-btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
}

.preferences-btn {
  background-color: $dark-background-color;
  color: white;
  outline: none;
  border: none;
  margin: 1rem 0.5rem 0 0;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  width: 100%;

  &:hover {
    background-color: $light-hover-gray;
    transition: background-color 0.5s ease;
  }
}

.preferences-btn-background {
  background-color: $orange;

  &:hover {
    background-color: $light-orange;
    transition: 0.5s ease;
  }
}

.preferences-btn:active {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}

.preferences-item-square {
  background: inherit;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.delete-account-container {
  display: flex;
  transition: 2s;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 2rem;
}

.delete-account-container-show {
  opacity: 1;
  width: 100%;
}

.dropdown-menu-custom {
  width: 100%;
  max-height: 12rem;
  overflow-y: scroll;
  background: #181a1b;
  color: #e4e4e4;
}

.user-display {
  font-family: Geomanist-Medium;
}

.close-icon-container {
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f3;
  padding: 4px;
  cursor: pointer;
}

.close-icon-container:hover {
  transition: all 0.2s;
  background-color: #e7e8ee;
}

@media screen and (max-height: 1000px) {
  .preferences-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
  }

  .preferences-modal {
    width: 57rem;
    color: $platinum-gray;
    overflow-y: visible !important;

    .preferences-modal-content {
      background: transparent;
      border: none;
      min-height: 400px;

      .profile-menu-content {
        background: #fff !important;
        padding: 1rem;
        width: 100%;
        box-shadow: 0 16px 119px #00000091;
      }

      .profile-menu {
        background: $dark2-background-color;
        min-width: 15rem;
      }
    }
  }
  .cookies-checkboxes-settings {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: -0.5rem 0 0 0;
  }
}
