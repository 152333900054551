@import '../../../../../utils/font-variables';
@import '../../../../../utils/variables';

.search-results {
  .table {
    margin-bottom: 4rem;
  }
  .cart-table-container {
    table {
      tbody {
        tr {
          cursor: pointer;
          td {
            padding: 12px 0 11px 16px;
          }
        }
      }
    }
    .table-row-no-results {
      @include m-26;
      margin: 80px 0;
    }
  }
}
