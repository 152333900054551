.picture-attachment {
  text-align: center;

  .fileContainer {
    background-color: #ffffff !important;
    border: 1px solid #808080;
    border-radius: 0;
    margin: 1rem;
    padding: 1rem;

    &--uploader {
      @extend .fileContainer;
      border: 1px dashed #808080;
      margin: 0;
    }

    &--images {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
    }

    .file-uploader-text {
      cursor: auto;
      font-size: 16px;
    }

    .file-uploader-link {
      margin-top: 1rem;
      cursor: pointer;
      margin-inline: auto;
      padding: 4px 18px;
    }

    .chooseFileButton {
      background: white;
      color: #32343a;
      border: 1px solid #000;
      border-radius: 4px !important;
      width: fit-content;

      &:hover {
        background-color: white;
      }
    }
  }

  .image-item {
    position: relative;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    & img[data-delete=true] {
      filter: grayscale(100%) blur(10px);
    }

    &[data-error=true] {
      & .react-select__control {
        border-color: red !important;
      }
    }

    & .chooseFileButton {
      width: 100%;
      padding: 5px 10px;
      margin-top: 10px;
    }

    .deleteImage {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      translate: 50% -25%;
      background: #ff4f00 !important;
      color: #464e57 !important;
      border-radius: 4px !important;
      padding: 4px;
      cursor: pointer;

      &:hover {
        background: #ae4700 !important;
      }
    }

    & .input-container.select-field {
      width: 100%;
      margin-bottom: 0;
    }

    & .react-select {

      &__indicator-separator {
        display: none;
      }
    }
  }
}
