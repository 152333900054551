@import 'src/utils/variables.scss';

.close-button-svg {
  &:hover .cross-line {
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    fill: $light-hover-gray;
  }
}

@mixin base-aic-icon {
  cursor: pointer;

  &:hover path {
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    fill: $light-hover-gray;
  }
}

.custom-aic-icon {
  @include base-aic-icon;

  height: 24px;
  width: 24px;
}

.custom-aic-icon-small {
  @include base-aic-icon;
  height: 20px;
  width: 20px;
}

.custom-aic-icon-mini {
  @include base-aic-icon;
  height: 16px;
  width: 16px;
}

.rotate {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  transform: rotate(90deg);
}

.custom-aic-icon-stroke {
  cursor: pointer;

  &:hover path {
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    stroke: $light-hover-gray;
  }
}

.clear-input {
  stroke: $light-hover-gray !important;

  &:hover path {
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    stroke: white;
  }
}

.custom-aic-icon-stroke-mini {
  height: 10px;
  cursor: pointer;

  &:hover path {
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    stroke: $light-hover-gray;
  }
}

.nohover {
  cursor: unset;
  &:hover {
    fill: #32343a;
  }
}

.stroke-gray path {
  stroke: #b0b1b5;
}

.fill-gray path {
  fill: #b0b1b5;
}

.normal-svg {
  height: 16px;
}

.medium-svg {
  height: 12px;
}

.mini-svg {
  height: 10px;
}

.expand-icon-product-parts {
  margin-right: 5px;
  margin-top: -2px;
}

.map-pin-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
