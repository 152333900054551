@import '../../../../../../utils/font-variables';
@import '../../../../../../utils/variables';

.search-results {
  .cart-table-container {
    table {
      thead {
        position: sticky;
        z-index: 1;
        background: #fff;
        top: -25px;
        tr {
          th {
            @include r-13;
            color: #7a7d85;
            padding: 12px 0 11px 16px;
          }
        }
      }
    }
  }
}
