@import 'src/utils/variables.scss';

.navbar-container {
  background: $dark-background-color;
  z-index: 6;
  position: absolute;
  width: 100%;
  margin: auto;
  height: 5rem;
  display: flex;
  flex-direction: column;

  .navbar-top {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    height: 3rem;
    background: $dark-background-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-bottom {
    width: 100%;
    background: white;
    padding: .4rem 0;

    &--content {
      width: 100%;
      max-width: 1400px;
      margin: auto;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.navbar-left {
  display: flex;
  align-items: center;

  .navbar-brand-figure {
    cursor: pointer;
    padding: 0 1.5rem 0 0.5rem;
    margin: 0;

    img {
      height: 2rem;
      width: 2rem;
    }
  }

  .navbar-left-text {
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    color: white;
    text-decoration: none;
    font-family: 'Geomanist-Regular';

    &.active-tab {
      color: $orange;
    }
  }

  .navbar-left-path-container {
    padding: 0 0 0 0.5rem;

    span:not(:last-child) {
      color: $jet-gray;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .navbar-left-path-text {
    font-size: 0.8rem;
    cursor: default;
    color: $dark-background-color;
  }

  .navbar-left-path-arrow {
    font-size: 0.8rem;
    padding: 0 0.3rem;
  }
}

.navbar-right {
  float: right;
  display: flex;

  .navbar-icon {
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background: $davys-gray;
      clip-path: circle(40%);
    }

    svg {
      height: 1.2rem;
      width: 1.2rem;
      fill: white;
    }
  }

  .disabled-cart {
    pointer-events: none;
    opacity: 0.4;
  }

  .cart-length {
    border-radius: 90px;
    position: absolute;
    width: 17px;
    height: 17px;
    font-size: 10px;
    background: $orange;
    padding: 1px;
    display: flex;
    justify-content: center;
    color: white;
    margin-top: -18px;
    margin-left: 2px;
    cursor: pointer;
  }

  .navbar-btn {
    height: 1.6rem;
    padding: 0.2rem 1rem;
    margin: 0 0.2rem;
    font-size: 13px;
    line-height: 17px;
    color: $dark-background-color;
    border: 1px $dark-background-color solid;
    cursor: pointer;
    font-family: 'Geomanist-Regular';

    &:hover {
      background: $light-hover-gray;
      color: white;
    }
  }
}
