@import '../../../utils/variables';
@import '../../../utils/font-variables';

.available-serial-numbers {
  padding-left: 1rem;
  margin-top: -2rem;
  padding-bottom: 1rem;
  width: 16rem;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 26px;
    padding-right: 1rem;
  }

  .available-serialnumbers-items {
    display: flex;
    flex-direction: column;
    width: max-content;
    font-size: 13px;
    color: #32343a;

    .available-serialnumbers-item {
      cursor: pointer;
      display: flex;
      padding: 6px;
      margin: 6px;
      color: gray;
      border: 1px solid gray;

      &:hover {
        color: white;
        border: 1px solid #b0b1b5;
        background: #b0b1b5;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
      }

      .vertical-separtor {
        width: 1px;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
  }
}

.sparepart-row-with-pic {
  display: flex;
  padding-left: 1rem;

  .row-pic {
    background: #e4e5e7;
    height: 80px;
    width: 107px;
  }

  img {
    height: 80px;
    width: 100%;
    min-width: 107px;
  }

  .sparepart-double-row {
    padding-left: 1rem;
  }
}

.find-parts-modal {
  width: 100vw;
  height: 100vh;
  padding: 0;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $main-background-color;
  transition: 1s ease;

  .find-parts-body {
    overflow-y: scroll;
    color: black;
    height: -webkit-fill-available;
    padding: 6rem 182px 0 182px;

    .inner-body {
      color: black;
    }
  }

  .above-results {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
    align-items: center;

    .results-title {
      font-size: 36px;
    }
  }

  .claim {
    font-size: 42px;
  }

  .subclaim {
    color: #b0b1b5;
  }

  .modal-content {
    border: none;
    padding: 6rem 6rem 0 6rem;
  }

  .modal-header {
    border-bottom: none;
    padding: 0 0 0 6rem;
  }

  .close-modal-button {
    svg {
      fill: black;

      path {
        fill: black;
      }
    }
  }

  .modal-logotype {
    img {
      height: 64px;
    }
  }
}

.selectable-sn {
  cursor: pointer;
  padding-left: 1rem;

  &:hover {
    background: #b6b6b6;
  }
}

.cart-header {
  background: #323431;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
}

.quantity-cell {
  // display: flex;

  input {
    width: 3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.cart-modal-overlay {
  align-items: start;
  padding-top: 2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-place-order {
  float: right;
  margin-right: 1rem;
  border: 1px solid $primary-accent;

  background: white;
  color: $primary-accent;
  transition: all 0.2s ease-in;
  outline: none;
  a {
    outline: none;
    color: #f8f9fa;
    border: none !important;

    &:hover {
      color: white;
    }
  }

  &:hover {
    background: $primary-accent;
    color: white;
  }
}

.spareparts-table-button {
  font-weight: 600;
  font-size: 2rem;
  color: #a9a9a9;
  cursor: pointer;

  &:hover {
    color: $primary-accent;
  }
}

.btn-remove-from-cart {
  cursor: pointer;
  margin-bottom: -5px;
  margin-left: 1rem;

  path {
    fill: gray;
  }
  &:hover {
    path {
      fill: $primary-accent;
    }
  }
}

.first-column {
  padding-left: 8px;
}

.last-column {
  padding-right: 8px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .find-parts-modal {
    .find-parts-body {
      padding: 6rem 100px 0 100px;
    }
  }
}

.recent-spareparts-enter {
  opacity: 0;
  transform: scale(0.9);
}
.recent-spareparts-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.recent-spareparts-exit {
  opacity: 1;
}
.recent-spareparts-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
