@import 'src/utils/font-variables';
@import 'src/utils/variables';

.spareparts-search-tooltip {
  text-decoration: underline;
  cursor: pointer;
}

.spareparts-search-tooltip-content {
  padding: 1rem;
  font-size: 13px;
  max-height: 300px;
  overflow-y: auto;

  .suitable-for-row {
    padding-bottom: 4px;
    .index {
      color: #afafaf;
    }
  }
}

.light-row {
  .suitable-for-row {
    padding-bottom: 4px;
    .index {
      color: gray;
      font-size: 13px;
    }
  }
}
