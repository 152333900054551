@import 'src/utils/variables.scss';
@import 'src/utils/font-variables.scss';

.cartModal {
  .modal-content {
    padding: 1rem;
    flex-wrap: wrap;
    align-content: unset;
    justify-content: center;
  }

  .modal-header {
    display: contents;
  }
}

.new-cart-modal {
  .below-cart-table-actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;

    button {
      font-weight: 700;
    }

    .modal-dialog {
      z-index: 10;
      margin-top: 15rem;
      background: #ffffff;
      padding: 20px 15px;

      .modal-content {
        padding: 0;

        .modal-header {
          padding: 20px 15px;

          .modal-title {
            @include r-26;
            font-size: 22px;
            color: black;
            text-align: center;
          }
        }

        &--buttons {
          display: flex;
          padding: 25px 10px 10px;
          width: 100%;
          justify-content: space-around;
          align-items: center;

          & button {
            font-weight: 700 !important;
          }
        }

        .aic-small-btn-outline:hover {
          color: white;
          font-weight: 700;
        }

        .aic-small-btn-outline {
          @include r-13;
          padding: 3px 10px;
          border-radius: 0;
        }

        .clear-cart:hover {
          background: #ff4f00;
        }
      }
    }

    .fade:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
}

.quantity-modificator {
  display: flex;
  align-items: flex-end;

  .quantity-button {
    border: none;
    outline: none;
    background: transparent;
    border-radius: 50%;
  }
}

.above-table {
  padding-bottom: 1.5rem;
}

.cart-bottom-section {
  div {
    border-radius: 0;
  }

  button {
    border-radius: 0;
  }

  .btn-place-order {
    border-radius: 0;
  }
}

.new-cart-modal {
  width: 100vw;
  height: 100vh;
  padding: 0;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $main-background-color;

  .claim {
    font-size: 36px;
    padding: 40px 0;
  }

  .inner-body {
    max-width: 1076px;
    width: 100%;
  }

  .cart-body {
    color: black;
    display: flex;
    justify-content: center;
    padding: 3rem 6rem 6rem 6rem;
  }

  .modal-content {
    border: none;
    padding: 6rem;
  }

  .modal-header {
    position: fixed;
    padding-top: 2rem;
    padding-right: 2rem;
    border-bottom: none;
    width: 100vw;
    background: #f2f2f3;
    justify-content: end;
    z-index: 2;
  }

  .close-modal-button {
    padding: 0;

    svg {
      fill: black;

      path {
        fill: black;
      }
    }
  }

  .cart-buttons {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    span {
      position: absolute;
      bottom: -17px;
      right: 0;
      z-index: 3;
      font-size: 10px;
    }

    & > button {
      font-weight: 700 !important;

      &:disabled {
        background: #8b8b8b;
        user-select: none;
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}

.sparepart-normal-row {
  padding: 12px 0 11px 16px;
  vertical-align: top;
}

.sparepart-row-with-pic {
  display: flex;

  .row-pic {
    background: #e4e5e7;
    height: 80px;
    width: 107px;
  }

  img {
    height: 80px;
  }

  .sparepart-double-row {
    padding-left: 1rem;
  }
}

.quantity-cell {
  // display: flex;

  input {
    width: 3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.cart-modal-overlay {
  align-items: start;
  padding-top: 2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.comments-container {
  textarea {
    resize: none;
    width: 100%;
    height: 5rem;
    background: #fdfdfd;
    border: 1px solid #e5e5e6;
    margin: 2rem 0 1rem 0;
    padding: 0.5rem 1rem;
    font-size: 14px;
  }
}

.btn-place-order {
  float: right;
  margin-right: 1rem;
  margin-bottom: 2rem;
  border: 1px solid $primary-accent;
  background: white;
  color: $primary-accent;
  transition: all 0.2s ease-in;
  outline: none;

  a {
    outline: none;
    color: #f8f9fa;
    border: none !important;

    &:hover {
      color: white;
    }
  }

  &:hover {
    background: $primary-accent;
    // border: 1px solid $primary-accent;
    color: white;
  }
}

.order-info {
  padding-bottom: 1rem;
  font-size: 1rem;
  background: #4caf50;
  text-align: center;
  padding-top: 1rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.order-error {
  padding-bottom: 1rem;
  font-size: 1rem;
  background: #f44336;
  text-align: center;
  padding-top: 1rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.order-result-items {
  margin-top: 2rem;
}

.cart-table-container {
  table {
    width: -webkit-fill-available;
    font-size: 14px;
    border-collapse: collapse;

    thead {
      top: 56px;
      position: sticky;
      background: white;
      z-index: 1;
    }
  }

  th {
    border-bottom: 2px solid #e5e5e6;
    @include r-13;
    padding: 12px 21px 11px 16px;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #7a7d85;
  }

  tbody {
    tr {
      border-bottom: 1px solid #e5e5e6;
    }

    td {
      padding: 16px;
      line-height: normal;
    }
  }
}

.spareparts-table-button {
  font-weight: 600;
  font-size: 2rem;
  color: #a9a9a9;
  cursor: pointer;

  &:hover {
    color: $primary-accent;
  }
}

.btn-remove-from-cart {
  cursor: pointer;
  margin-bottom: -5px;
  margin-left: 1rem;

  path {
    fill: gray;
  }

  &:hover {
    path {
      fill: $primary-accent;
    }
  }
}

.first-column {
  padding-left: 8px;
}

.last-column {
  padding-right: 8px;
  text-align: center;
}

.modal-backdrop.show {
  z-index: 2;
}
