.product-info-sparepart-groups {
  padding-bottom: 1rem;

  .sparepart-group-line {
    padding: 2px 2rem;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: gray;
    }
  }
}
 