.login-card-register {
  margin: auto;
  float: none;
  //background: #464e57 !important;
  color: white !important;
}

.error {
  color: #ff2f28;
}

.error-margin {
  display: block;
  margin-bottom: 22px !important;
}

.register-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: #464e57;
  color: #fff;
  z-index: 100000;
}

.chekbox-form {
  font-size: 0.85rem;
  a {
    display: contents;
  }

  .span-link {
    color: #ff4f01;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: #5d646a;
    }
  }
}
