@import 'src/utils/variables.scss';
@import 'src/utils/font-variables';
@import 'src/utils/recent-items-styles';

.ReactModal__Overlay {
  z-index: 99 !important;
}

.modal-logotype {
  img {
    height: 64px;
  }
}

.close-modal-button {
  padding: 3rem 6rem 0 0;
  color: black;

  svg {
    cursor: pointer;
  }
}

.spareparts-categories-modal {
  width: 100%;
  height: 100%;
  padding: 0;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  background: #f2f2f3;
  color: #32343a;
  overflow: hidden;

  .categories-title {
    font-size: 26px;
    padding-right: 1rem;
    padding-bottom: 24px;

    @include m-26;
  }

  .categories-recent {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 2rem 0;
    width: 100%;

    @include recent-items-styles;
  }

  .categories-selector-container {
    padding-top: 2rem;
    width: 100%;

    .categories-tree {
      display: flex;
      justify-content: space-between;

      .categories-column {
        width: 20rem;
        margin-right: 3rem;

        &.not-picked {
          cursor: default;
          color: #484a51;
        }

        .categories-column-title {
          font-weight: 600;
        }

        .categories-list {
          .disabled-category {
            @include r-13;
            color: #b0b1b5;
          }
          .category-item {
            cursor: pointer;
            padding: 4px;
            margin: 1px -5px;
            color: #b0b1b5;
            @include r-13;

            &:hover {
              color: #32343a;
            }
            &.picked {
              color: #32343a;
            }
          }
        }
      }
    }
  }

  .claim {
    font-size: 26px;
  }

  .modal-content {
    border: none;
    padding: 4rem 2rem;
  }

  .modal-header {
    border-bottom: none;
    padding: 0 0 0 6rem;
  }
}

.selectable-sn {
  cursor: pointer;
  padding-left: 1rem;

  &:hover {
    background: #b6b6b6;
  }
}

.cart-modal {
  width: 100%;
  max-width: 50rem;
  background: white;
  outline: none;
  padding: 0;
  max-height: calc(100% - 4rem);
}

.cart-body {
  color: black;
  padding: 2rem;
}

.cart-header {
  background: #323431;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
}

.quantity-cell {
  // display: flex;

  input {
    width: 3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.cart-modal-overlay {
  align-items: start;
  padding-top: 2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-place-order {
  float: right;
  margin-right: 1rem;
  border: 1px solid $primary-accent;
  border-radius: 5px;
  background: white;
  color: $primary-accent;
  transition: all 0.2s ease-in;
  outline: none;
  a {
    outline: none;
    color: #f8f9fa;
    border: none !important;

    &:hover {
      color: white;
    }
  }

  &:hover {
    background: $primary-accent;
    // border: 1px solid $primary-accent;
    color: white;
  }
}

.spareparts-table-button {
  font-weight: 600;
  font-size: 2rem;
  color: #a9a9a9;
  cursor: pointer;

  &:hover {
    color: $primary-accent;
  }
}

.btn-remove-from-cart {
  cursor: pointer;
  margin-bottom: -5px;
  margin-left: 1rem;

  path {
    fill: gray;
  }
  &:hover {
    path {
      fill: $primary-accent;
    }
  }
}

.first-column {
  padding-left: 8px;
}

.last-column {
  padding-right: 8px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .spareparts-categories-modal {
    .modal-content {
      border: none;
    }
  }
}

.categories-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
