@import 'src/utils/variables.scss';
@import 'src/utils/font-variables';
@import 'src/scenes/Spareparts/SparepartCategories/SparepartCategoriesPage';

.results-by-sn-container {
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  padding-inline: 50px;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .column-title {
    font-weight: 700;
  }
}

.product-info-item {
}
.product-image-wrapper {
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.product-image {
  display: block;
  max-width: 100%;
  max-height: 250px;
}

.item-title {
  color: #b0b1b5;
  font-size: 13px;
}

.item-info {
  font-size: 13px;
  text-transform: uppercase;
}

.category-item {
  cursor: pointer;
  padding: 4px;
  margin: 1px -5px;
  color: #b0b1b5;
  @include r-13;
  font-size: 14px;

  &:hover {
    color: #32343a;
  }
}

.no-groups-text {
  white-space: pre-line;
}
