@import 'src/utils/variables.scss';

@charset "UTF-8";

.my-toggle {
  background-color: #e8e9ef;
  border-radius: 99px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding: 0 !important;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  width: 160px;
  height: 2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.6s;
  box-shadow: inset -3px -5px 6px rgba(255, 255, 255, 0.8),
    inset 3px 3px 6px rgba(0, 0, 0, 0.3);

  .my-toggle-btn {
    position: relative;
    background-color: transparent;
    z-index: 2;
    display: flex;
    width: 48px;
    height: 32px;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }
  .indicator {
    border-radius: 99px;
    background-color: #e8e9ef;
    box-shadow: -4px -4px 7px rgba(255, 255, 255, 1),
      3px 3px 6px rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 28px;
    width: 50px;
    cursor: default;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .animated {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-in-out;
  }

  .middle-dot {
    width: 8px;
    height: 8px;
    pointer-events: none;
    border-radius: 99px;
    border: #a0a1a9 solid 2px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }

  .left {
    left: 26px;
  }
  .right {
    left: 135px;
  }
}
