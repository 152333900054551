.product-history-timeline-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 123px;
  cursor: pointer;
  position: relative;

  &-svg {
    padding: 20px 24px 0 0;
  }
  .hidden-header {
    color: black;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 24px;
    color: #7a7d85;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

    :nth-child(2) {
      color: #32343a;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.025em;
      text-align: left;
    }
  }

  span {
    z-index: 3;
  }

  .hidden-card-svg {
    position: absolute;
    width: 123px;
    opacity: 0.2;
  }
}
