@font-face {
  font-family: 'Geomanist-Bold';
  src: url('assets/fonts/Geomanist-Bold.otf');
}

@font-face {
  font-family: 'Geomanist-Medium';
  src: url('assets/fonts/Geomanist-Medium.otf');
}

@font-face {
  font-family: 'Geomanist-Regular';
  src: url('assets/fonts/Geomanist-Regular.otf');
}

@font-face {
  font-family: 'Geomanist-Light';
  src: url('assets/fonts/Geomanist-Light.otf');
}

body {
  /* height: 10rem !important; */
  margin: 0;
  padding: 0;
  font-family: Geomanist-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f3;

  /* background: linear-gradient(to right bottom, #5a6066, #2d3034); */
  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.snack-root {
  z-index: 100000000 !important;
}
