.product-info {
  &-wrapper {
    grid-area: info;
    background: transparent;
    padding-top: 22px;
  }

  &-card {
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #0000000d;
    margin-bottom: 24px;
    transition: 1s ease;

    &:hover {
      box-shadow: 0px 8px 30px 0px #989393;
    }

    &-header {
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      flex-direction: row;

      svg {
        width: 25px;
        color: #32343a;
      }

      p {
        font-weight: 600;
      }
    }

    &-list {
      &-item {
        .status-block {
          display: flex;
          align-items: center;

          :nth-child(1) {
            margin-right: 8px;
          }
        }

        .target {
          display: flex;
        }
      }

      a {
        color: #32343a;
        text-decoration: underline;
        font-size: 13px;
      }
    }
  }
}
