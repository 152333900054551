@import '../../../utils/variables';
@import '../../../utils/font-variables';

.welcome-spareparts {
  height: 100vh;
  background: $main-background-color;

  &-container {
    background: $main-background-color;
    display: grid;
    grid-row-gap: 20%;
    grid-template-columns: 50% 50%;
    grid-auto-rows: auto;
    grid-template-areas: 'm i';
  }
  &-menu {
    min-width: 331px;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    margin-left: 100px;
    align-items: center;
  }
  &-card {
    background: #ffffff;
    box-shadow: 0 0 10px 0 #0000000d;
    transition: 1s ease;
    margin-left: 100px;
    width: 350px;

    &:hover {
      box-shadow: 0 8px 30px 0 #989393;
    }
    &-left-inside {
      display: flex;
      flex-direction: column;
      padding: 24px;

      .welcome-spareparts-header {
        padding-bottom: 24px;
        @include m-16;
        span {
        }
      }
      .welcome-spareparts-message {
        span {
          @include r-16;
        }
      }

      .spareparts-hello-buttons {
        display: flex;
        margin-top: 2rem;
        button {
          padding: 0px;
          width: 142px;
          height: 40px;
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }

  .welcome-spareparts-photo-container {
    width: 100%;
    height: 100vh;
    margin: 0;
    &-image {
      position: relative;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    img {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

@media all and (max-width: 1024px) {
  .welcome-spareparts {
    &-container {
      background: $main-background-color;
      display: grid;
      grid-row-gap: 20%;
      grid-template-columns: 100% 0;
      grid-auto-rows: auto;
      grid-template-areas: 'm i';
    }
    &-menu {
      margin: 0 auto;
    }
    &-card {
      margin-left: 0;
    }
  }
}

@media all and (min-width: 1450px) {
  .welcome-spareparts {
    &-container {
      grid-template-columns: 50% 50%;
      grid-auto-rows: auto;
      grid-template-areas: 'm i';
    }
    &-menu {
      margin: 0 auto;
    }
    &-card {
      width: 450px;
      margin: 0;
      &-left-inside {
        .welcome-spareparts-header {
          @include m-26;
          span {
            font-size: 24px;
          }
        }

        .welcome-spareparts-message {
          span {
            @include r-26;
            font-size: 21px;
          }
        }

        .spareparts-hello-buttons {
          //font-size: 16px;
        }
      }
    }
  }
}

@media all and (min-width: 1920px) {
  .welcome-spareparts {
    &-card {
      width: auto;
      margin: 0;
      &-left-inside {
        .welcome-spareparts-header {
          @include m-26;
          span {
            font-size: 28px;
          }
        }

        .welcome-spareparts-message {
          span {
            @include r-26;
            font-size: 23px;
          }
        }

        .spareparts-hello-buttons {
          font-size: 19px;
        }
      }
    }
  }
}
