.product-info-card {
  transition: all 0.3s ease 0s;

  .register-iot-form {
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
  }

  .sensor-left-line {
    line-height: 13px;
  }

  .settings-button {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }

  .request-status {
    padding: 10px 16px;
  }

  .device-name {
    padding: 12px 16px;
    font-size: 15px;
  }

  .underlined {
    text-decoration: underline;
  }

  .sensor-row {
    font-size: 13px;
    display: flex;
    padding-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .left-column {
      display: flex;
      flex-direction: row;
      padding: 0px 16px 5px;
      align-items: flex-start;
      flex-wrap: nowrap;
      vertical-align: middle;

      .customcheck {
        display: inline-block;
        width: auto;
        padding-right: 5px;
        font-size: 13px;
        font-weight: 800;
      }

      .checkmark {
        top: 0;
        left: 0;
      }
    }

    .right-column {
      padding: 0px 16px 5px;

      .measurement {
        line-height: 1em;
      }
    }
  }
}
