@import 'src/utils/variables.scss';

.blacked {
  color: #32343a;
}

.product-parts-tooltip-content {
  padding: 1rem;
  width: 150px;
}

.product-list-container {
  height: 100vh;
  background: $main-background-color;
}

.product-parts-container {
  display: flex;
  flex-flow: column;
  height: 100vh;
  background: $main-background-color;
  padding: 7rem 6rem 1rem 6rem;
  color: #32343a;
  font-size: 13px;

  .padded-left {
    padding-left: 24px;
  }

  .upper-pane {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 0 0 16px 26px;

    .boiler-name-and-surname {
      font-size: 26px;
    }

    .model-and-sn {
      width: 550px;
      justify-content: space-between;
    }

    & div {
      display: flex;
    }
  }

  .right-pane {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    .product-parts-table {
      .centered-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .round-white-staple-hover {
        padding: 6px;

        &:hover {
          background: white;
          box-shadow: 0 8px 15px rgba(51, 65, 99, 0.08),
            10px 8px 15px rgba(93, 102, 126, 0.05);
          border-radius: 100px;
        }
      }

      .included_in-container {
        overflow: scroll;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;

        a {
          color: rgba(0, 0, 0, 0.87);
          padding-left: 5px;
          text-decoration: none;
          white-space: nowrap;
          cursor: pointer;
        }

        a:hover {
          color: #2c5d91;
        }

        &::-webkit-scrollbar {
          width: 3px;
        }
      }

      .product-parts-row-with-tooltip {
        display: flex;
      }

      .quantity-button {
        user-select: none;
      }

      .rdt_Table {
        height: calc(100vh - 17rem);

        .rdt_TableCol_Sortable {
          @include bolded;
          color: #7a7d85;
        }
      }
    }

    .collapsed-details {
      margin-left: 1rem;
      padding: 1rem;

      .icon-column {
        box-shadow: 0 5px 15px rgba(51, 65, 99, 0.08),
          10px 5px 15px rgba(93, 102, 126, 0.05);
        border-radius: 100px;
        background: white;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content-column {
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        width: 100%;

        .first-line {
          color: #32343a;
          font-size: 16px;
          @include bolded;
        }

        .second-line {
          font-size: 13px;
          color: gray;
        }
      }

      .warning-replace {
        padding: 1rem;
        @include with-shadow;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #b0b1b5;
        background: white;
        margin-bottom: 16px;
      }

      .product-history {
        @include with-shadow;
        padding: 1rem;
        background: white;
        margin-top: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;

        .history-items {
          margin-top: 6px;
          width: 100%;

          thead {
            border-bottom: 1px solid #e5e5e6;
            border-top: 1px solid #e5e5e6;
            color: #7a7d85;

            th {
              padding-left: 8px;
            }
          }

          td {
            padding: 4px 8px;
          }

          .history-item-row {
            .history-item {
              .action-name {
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .product-parts-table-footer {
      display: flex;
      padding-top: 8px;
      flex-direction: row;
      background: $main-background-color;
      justify-content: flex-end;
      align-items: baseline;

      .cart-count {
        font-size: 13px;
        color: $jet-gray;
        padding: 0 5px 8px 5px;
        display: flex;
        align-items: center;
      }

      .spareparts-table-footer-btns {
        display: flex;

        & > div {
          margin-left: 1rem;
        }
      }
    }
  }
}

.loading-spinner {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
}
