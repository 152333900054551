.password-bar-container {
  width: 100%;
  height: 4px;
  background: grey;
  margin: 0.5rem 0 1.5rem 0;
  .password-bar-difficulty {
    height: 100%;
    transition: 0.3s ease-in;
  }
  .password-bar-description {
    font-size: 0.8rem;
  }
}
