.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
  justify-content: center;
}

.logotype svg {
  fill: #ffffffd1;
}

.login-input {
  border-radius: 8px !important;
  background: #ffdfd1 !important;
  border-color: #30363b7a !important;
}

.login-header {
  color: #ffdfd1;
  font-weight: 700;
  font-size: 1.9rem;
}

.btn-login {
  color: #ffdfd1 !important;
  border: 1px solid #343a40 !important;
  border-radius: 8px !important;
  transition: 400ms !important;
  background: #ff4f01 !important;
}
.btn-login:hover {
  background: #343a40 !important;
  color: #ffdfd1 !important;
}

.register-invite {
  font-size: 0.85rem;
  color: #ffffff;
  max-width: 60%;

  a {
    color: #ff4f01;
  }

  a:hover {
    text-decoration: none;
    color: #5d646a;
  }
}

.reset-password {
  font-size: 0.85rem;
  color: #fff690;
  max-width: 100%;
  cursor: pointer;

  a {
    color: #fff690;
  }

  a:hover {
    text-decoration: none;
    color: #9a914d;
  }
}

.btn-register {
  background: #398900 !important;
  color: #fefff3 !important;
  border: 1px solid #343a40 !important;
  border-radius: 8px !important;
  transition: 400ms !important;
  font-weight: bold !important;
}
.btn-register:hover {
  background: #343a40 !important;
  color: #ffdfd1 !important;
}

.login-container {
  width: 87%;
  max-width: 100%;
}

.login-card {
  background-color: #161c23 !important;
  box-shadow: 2px 6px 19px #00000080;
  width: 22rem;
  margin-top: -11vh;
  float: right;
}

@media (max-width: 700px) {
  .login-container {
    width: 100%;
  }

  .login-card {
    margin: -11vh auto;
    float: none;
  }
}
