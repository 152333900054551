@import 'src/utils/variables.scss';
@import 'src/utils/checkbox-styles';

.tooltip-location {
  font-size: 14px;
  padding: 10px;
}

.map-tooltip {
  top: -10px;
  right: -10px;
  background: white !important;
  color: #7a7d85;
  z-index: 99999;
  width: fit-content;
  padding: 26px;
  box-shadow: 1px 3px 14px 0px #000000;
  max-height: 140px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  min-width: 210px;

  .tooltip-list {
    line-height: 20px;

    .tooltip-list-item {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .map-tooltip-arrow {
    left: 694.547px;
    top: 410.688px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 1001;
  }

  .tooltip-arrow {
    width: 10px;
    height: 10px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid white;
    right: -6px;
    top: 32px;
    position: absolute;
  }
}

.tbody,
.dashboard-header-grid {
  > div {
    margin: auto;
    //display: flex;
    //justify-content: center;
  }
}

.dashboard-header-container {
  margin: -2rem 0 -13px 0;
}

.react-tooltip-lite {
  background: #32343a;
  color: white;

  .tooltip-title {
    font-size: 16px;
  }
}

.react-tooltip-lite-arrow {
  border-color: #32343a;
}

.dashboard-tooltip {
  padding: 1rem;

  &.two-sections {
    display: flex;
    flex-direction: row;

    .left-section {
      display: flex;
      padding-right: 1rem;
    }
    .right-section {
      display: flex;
      flex-direction: column;
    }
  }

  .dashboard-tooltip-content {
    font-size: 13px;
  }
}

.google-map-tooltip {
  .google-map-tooltip-title {
    font-size: 16px;
  }
}

.dashboard-spinner {
  position: relative;
  left: 50%;
  top: 12%;
}

.dashboard-footer {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  .left-section {
    display: flex;
    align-items: flex-end;
  }

  .right-section {
    display: flex;
    align-items: flex-end;

    & > div {
      padding: 0 1rem;
    }

    .selcted-items-info {
      display: flex;

      div {
        padding: 0 2px;
      }
    }
  }
}

.gm-style-iw-c {
  border-radius: 0 !important;
}

.react-tooltip-lite {
  padding: 0 !important;
}

.own-tooltip {
  color: #cbcbcd;
  font-size: 11px;

  // multiselect
  .basic-multi-select {
    width: 150px;
    // margin-top: 5px;

    .multi-select__indicators {
      display: none;
    }
    .multi-select__control {
      padding-top: 4px;
      background: #5e6068;
      border: none;
      border-radius: 0;
      outline: none !important;
      border-color: transparent;
      box-shadow: unset;
      cursor: pointer;
    }

    .multi-select__multi-value {
      border-radius: 0;
      background: #cecfd1;
    }

    .multi-select__input {
      opacity: 0;
    }
  }

  span {
    & > div {
      box-shadow: 2px 5px 15px 3px #5f5f5f6b;
    }
  }

  .own-tooltip-body {
    padding: 24px 24px 8px 24px;
    color: gray;

    .checkbox-input-with-label {
      padding-bottom: 8px;
      display: flex;
      align-items: flex-end;
    }

    input {
      height: 26px;
      width: 150px;
      outline: none;
      padding: 0;
      background: #32343a;
      color: white;
      border-color: #5e6068;
      border-style: solid;
      border-width: 0 0 1px 0;
      &:focus {
        border-bottom: 1px solid white !important;
      }
    }
  }

  .checkbox-input-with-label {
    display: flex;
    align-items: center;

    .checkbox-label {
      padding-left: 8px;
      display: flex;
      align-items: center;

      .checkbox-text {
        padding-left: 14px;
      }

      .dot {
        height: 8px;
        width: 8px;
        border-radius: 90px;
      }
    }
  }

  .ok-button {
    cursor: pointer;
    font-size: 15px;
    line-height: 27px;
    color: #cbcbcd;
    text-align: center;
    background: #484a51;
    width: 100%;
    height: 32px;
  }
}

#boilers-table {
  margin-top: -2rem;
  width: -webkit-fill-available;
}

#boilers-table-copy {
  position: absolute;
  margin-top: -2rem;
  width: -webkit-fill-available;
}

.boilers-table-copy-initial {
  margin-left: 1rem;
  margin-right: 1rem;
}

#resizer {
  cursor: row-resize;
  user-select: none;
  background: white;
  border-radius: 8px 8px 0 0;
  width: 5rem;
  height: 1rem;
  position: absolute;
  margin-top: -43px;
  left: 48%;
  font-weight: 800;
  text-align: center;
  line-height: 13px;
}

#table-head {
  opacity: 0;
}

.my-products-map {
  position: absolute;
  height: 100vh;
  width: 100%;
  background: $main-background-color;
  display: flex;
  flex-direction: column-reverse;

  a {
    color: #212529;
  }

  .dashboard-map-container {
    height: 100%;
    width: 100hw;
  }

  .dashboard-table-container {
    height: -webkit-fill-available;
    overflow: overlay;
  }

  .dashboard-products-separator {
    border: none !important;

    &:hover {
      background: transparent !important;
    }
  }
}

/* ------------------- new dashboard ------------------- */

.myProductsMap {
  height: 100vh;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 5rem 0.5rem 0 0.5rem;
  position: relative;
  background: $main-background-color;
  display: flex;
  flex-direction: column;

  /* topPart => map */

  .topPartSettings {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    .topPartSettings__containerButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }

    .topPartSettings__containerButtonsMap {
      height: 2rem;
      margin: 1rem;
      display: flex;
      align-items: center;
      background: white;
      border-radius: 1rem;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    .topPartSettings__svgContainerSmall {
      width: 2rem;
      height: 2rem;
      font-size: 0.8rem;
      padding: 0.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      border: 0.3rem white solid;
      color: $dark-background-color;
      cursor: pointer;
      transition: 0.5s;
    }

    .topPartSettings__svgContainer {
      background: white;
      fill: $dark-background-color;
      width: 2rem;
      height: 2rem;
      margin: 0 0.2rem 0 0;
      padding: 0.4rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .backgroundDark {
      background: $dark-background-color;
      fill: white;
      color: white;
    }
  }

  .topPartMap {
    padding: 0;
  }

  .topPartMap__container {
    width: 100%;
    transition: 0.8s ease-in-out;
  }

  /* middlePart => parts */

  .middlePart {
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
    margin: 0;
    padding: 0 0 .5rem 0;
  }

  .middlePart__container {
    height: 100%;
    width: 100%;
    position: relative;
    //hide scrollbars
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .middlePart__container::-webkit-scrollbar-thumb, .middlePart__grid::-webkit-scrollbar-thumb {
    width: 0;
    border-radius: 0;
  }

  .middlePart__container::-webkit-scrollbar, .middlePart__grid::-webkit-scrollbar {
    height: 0;
  }

  .middlePart__container::-webkit-scrollbar, .middlePart__grid::-webkit-scrollbar {
    display: none;
  }

  .middlePart__grid {
    font-size: 0.8rem;
    outline: none;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .tr {
      width: 100%;
      height: 100%;
      display: flex;
      border: #e5e5e6 solid;
      border-width: 0 0 1px 0;
      justify-content: space-between;
      align-items: center;
      outline: none;

      &:hover {
        background: #e5e5e6;
      }
    }

    .th {
      background: white;
      font-family: 'Geomanist-Medium,' '-apple-system', 'BlinkMacSystemFont',
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 0.8rem;
      height: 4rem;
    }

    .selected-row {
      box-shadow: 0 5px 15px rgba(51, 65, 99, 0.08),
        10px 5px 15px rgba(93, 102, 126, 0.05);
      background: white !important;
    }

    .td {
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
    }

    // column wide

    .status_cell {
      width: 70px;
    }

    .family_cell {
      width: 100px;
    }

    .power_cell {
      width: 100px;
    }

    .sn_cell {
      width: 150px;
    }

    .index_cell {
      width: 130px;
    }

    .location_cell {
      min-width: 150px;
      max-width: 400px;
      flex: 1;
    }

    .date_cell {
      width: 140px;
    }

    .service_support_cell {
      width: 120px;
      text-align: center;
    }

    .last_action_cell {
      width: 140px;
    }

    .department_cell {
      width: 120px;
    }

    .chekbox_cell {
      width: 40px;
    }

    .filter-name {
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
      color: #7a7d85;
    }

    .filter-name-active {
      color: #32343a;
      font-weight: bold;
    }

    .in-circle {
      background: white;
      border-radius: 90px;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 13px;
      padding: 0.2rem;
      margin: 0.2rem 0;
      display: flex;
      justify-content: center;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    }

    .last-action-cell-double {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .last-action-cell-text {
      padding: 0 0.4rem 0 0;
    }

    .chekbox-cell-center {
      margin: 0;
      padding: 0;
      transform: translate(-2px, -3px);
    }

    .date-block {
      margin-top: 1rem;
    }

    .date-block-row {
      display: flex;
    }

    .date-block-input {
      margin: 0 1rem 0 0;
      width: 50%;
    }

    .header-filter-icon {
      height: 1.4rem;
      border-radius: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.1rem;
      fill: #7a7d85;
      cursor: pointer;

      svg {
        margin: 0.1rem;
      }
    }

    .header-filter-icon-active {
      padding: 0.2rem 0.4rem;
      background: #32343a;
      fill: white;
    }

    .header-filter-icon-open {
      height: 1.8rem;
      min-width: 1.8rem;
      border-radius: 0.9rem;
      background: #32343a;
      fill: white;
    }

    .header-filter-text {
      color: white;
      font-size: 0.9rem;
      font-weight: bold;
    }
  }

  /* bottomPart */
  .bottomPart {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    padding: 1rem 0rem;
    margin-top: .5rem;
    border-top: 2px solid $jet-gray;
  }

  .bottomPart__left {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }

  .bottomPart__right {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  .bottomPart__right_selected {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .bottomPart__right_btn {
    padding: 0.3rem 1.5rem;
    font-size: 1rem;
    margin: 0 0 0 0.5rem;
    border: 1px solid $dark-background-color;
    transition: 0.5s;

    &:active {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    &:hover {
      background: #61636b;
      box-shadow: 20px 20px 30px 10px #32343a14, -10px 10px 30px 10px #0000001a;
      color: white;
    }
  }

  .bottomPart__right_btn_dark {
    color: white;
    background: $dark-background-color;
  }

  @media (max-width: 500px) {
    .bottomPart {
      flex-direction: column;
      padding: 0.2rem 0.5rem;
    }

    .bottomPart__right_btn {
      padding: 0.1rem 1rem;
      font-size: 0.8rem;
    }
  }

  /* settings filters */

  .settings__filtersList {
    max-height: 0;
    background: white;
    overflow: hidden;
    transition: 1s ease-in-out;
  }

  .settings__filtersList__rows {
    max-height: 0;
    background: white;
    overflow: hidden;
    transition: 1s ease;
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .settings__filtersList_visible {
    max-height: 600px;
    margin: 0 0 1rem 0;
  }

  .settings__filtersList_container {
    padding: 1rem;
  }

  .settings__filtersList_header {
    margin: 0;
    color: #32343a;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Geomanist-Medium,' '-apple-system', 'BlinkMacSystemFont',
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  .hr {
    padding: 1rem 0 0 0;
    hr {
      background: #dadada;
      height: 0.2rem;
    }
  }

  .settings__filtersList_container_items {
    padding: 1rem 0 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .settings__filtersList_container_items_status {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .settings__filtersList_container_items_multiCheckBox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .inspectionOverdue_checkbox {
    padding: 15px 0px;
  }

  .settings__filtersList_label {
    display: flex;
    align-items: center;
    padding: 0.2rem;
    font-size: 0.9rem;
  }

  .settings__filtersList_input {
    margin: 0 0.5rem 0 0;
  }

  @include checkbox-styles;

  .tooltipFilter {
    width: 100%;
    height: 100%;
  }

  .right_btn_container {
    padding: 1rem 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .right_btn {
    background: white;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
    margin: 0 0 0 0.5rem;
    border: 1px solid $dark-background-color;
    transition: 0.5s;

    &:active {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    &:hover {
      background: #61636b;
      box-shadow: 0px 15px 40px 0px #32343a14, 0px 3px 20px 0px #0000001a;
      color: white;
    }
  }

  .right_btn_dark {
    color: white;
    background: $dark-background-color;
  }

  .dot {
    height: 8px;
    width: 8px;
    border-radius: 90px;
    margin-right: 0.3rem;
  }

  .filter-hint {
    padding-top: 0.5rem;
    font-size: 0.8rem;
    display: flex;
    margin: 0;
  }

  .filters-container-fieldset {
    width: 100%;
    padding: 1rem 0 0 0;
    position: relative;
  }

  .filters-clear-button {
    margin-top: 0.5rem;
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .filters-fieldset {
    margin-top: -0.5rem;
    padding: 0 2rem 0 1rem;
    background-color: transparent;
    border: rgba(139, 139, 139, 0.6) solid 1px;
    width: auto;
  }

  .filters-fieldset legend {
    color: $dark-background-color;
    font-size: 0.8rem;
    background-color: transparent;
    float: none;
    width: auto;
    margin: 0;
  }

  .filters-input {
    width: 100%;
    display: block;
    background: white;
    color: $dark-background-color;
    border: none;
    padding: 0 0 0.5rem 0;
    margin: 0;
    outline: none;
    box-shadow: none;
    font-size: 1rem;
  }

  .filter-label {
    margin: 1rem 0 0 0;
  }

  // multiselect
  .basic-multi-select {
    width: 100%;

    .multi-select__indicators {
    }

    .multi-select__control {
      min-height: 40px;
      background: white;
      border: none;
      border-radius: 0;
      outline: none !important;
      border: rgba(139, 139, 139, 0.6) solid 1px;
      box-shadow: unset;
      cursor: pointer;
      padding: 0.1rem 1rem;
    }

    .multi-select__multi-value {
      border-radius: 0;
      background: #f2f2f3;
    }

    .multi-select__value-container {
      padding: 0 !important;
      border: none !important;
      outline: none !important;
    }

    .multi-select__placeholder {
      font-size: 1rem !important;
      margin: 0;
    }

    .multi-select__input {
      opacity: 0;
    }
  }

  .filters__select_text {
    font-size: 1rem;
    padding: 0 0.5rem 0 0;
    margin: 0;
  }

  .multi-select__menu {
    border-radius: 0;
    border-color: rgba(139, 139, 139, 0.6);
  }

  .multi-select__menu-list {
    max-height: 150px;
    border-radius: 0;
  }

  .filters__calendar_view {
    width: 300px;
    height: 200px;
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    z-index: 2;
  }

  /* calendar css */

  .react-calendar__navigation {
    background-color: #32343a;
    font-size: 1rem;
    color: white;
    font-weight: bold;
  }

  .react-calendar__navigation button {
    color: white;
    font-weight: bold;
    background: transparent !important;
  }

  .react-calendar__navigation button[disabled] {
    background-color: #32343a;
    color: white;
    font-weight: bold;
  }

  .react-calendar__tile--now {
    background: #32343a;
    color: white;
    font-weight: bold;
    clip-path: circle(40%);
  }
}
