@import 'src/utils/variables';

.sparepart-page-container {
  background: $main-background-color;
  display: flex;
  padding: 6rem 6rem 6rem 6rem;
  height: 100vh;

  .in-circle {
    background: white;
    border-radius: 90px;
    width: 28px;
    height: 28px;
    font-size: 13px;
    padding: 3px;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 7px 4px #00000012;

    svg {
      width: 16px;
      height: 16px;
      margin: 3px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .left-pane {
    min-width: 432px;
    max-width: 686px;
    margin: 0 2rem 0 0;

    .kit-header {
      padding-bottom: 2rem;

      .kit-name {
        font-size: 26px;
      }

      .kit-index {
        font-size: 16px;
        color: #7a7d85;
      }
    }

    .replaced_by {
      background: white;
      padding: 1rem 2rem;
      font-size: 13px;
      margin: 0 0 2rem 0;
      display: flex;

      .replaced_by-content {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;

        .replaced-by-link {
          color: #7a7d85;
        }
      }
    }

    .replaces-container {
      box-shadow: 1px 1px 20px 0 #d0cfcf;
      background: white;
      padding: 1rem 2rem;
      font-size: 13px;
      margin: 0 0 2rem 0;
      display: flex;
      align-content: center;
      align-items: center;

      .replaces-content {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;

        .replaced-by-link {
          color: #7a7d85;
        }
      }
    }

    .kit-items-container {
      .sparepart-index {
        font-weight: 700;
      }

      table {
        width: -webkit-fill-available;
        font-size: 13px;
        border-collapse: collapse;
        text-align: center;

        thead {
          background: white;
          color: $jet-gray;

          th {
            font-weight: normal;
            padding: 16px;
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #e5e5e6;
          }

          td {
            padding: 8px;
            line-height: normal;

            .sparepart-description a {
              color: rgba(0, 0, 0, 0.87);
              text-decoration: none;

              &:hover {
                color: #2c5d91;
              }
            }
          }
        }
      }

      .kit-items-controls {
        padding-top: 25px;
        border-top: 1px solid $jet-gray;
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        .quantity-modificator {
          display: flex;
          align-items: flex-end;
        }

        .aic-btn {
          margin-left: 1rem;
        }
      }
    }
  }

  .right-pane {
    min-width: 528px;
    margin-top: 6rem;

    .pictures-grid {
      display: grid;
      grid-gap: 1rem;
      grid-auto-rows: auto;

      .grid-picture {
        background: white;

        .grid-no-image {
          height: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          flex-direction: column;
        }

        .grid-label {
          position: absolute;
          background: white;
          border-radius: 90px;
          height: 24px;
          width: 24px;
          padding: 1px 0 0 9px;
          margin: 8px;
        }
      }
    }
  }

  img {
    box-shadow: 1px 1px 20px 0 #d0cfcf;
  }
}

@media screen and (max-width: 1024px) {
  .sparepart-page-container {
    flex-direction: column;

    .right-pane {
      order: 0;
      margin: 40px 0;
    }
  }
}
